import { Controller } from "@hotwired/stimulus"

const TOOLTIP_OFFSET = 30;
const TOOLTIP_WIDTH = 350;

// Connects to data-controller="master--summaries"
export default class extends Controller {
  showTimeout = null
  hideTimeout = null

  hoverStart(e) {
    e.preventDefault()

    clearTimeout(this.hideTimeout)

    const tooltip = document.querySelector('.summaries__tooltip')
    const targetCount = Math.min(parseInt(e.target.textContent), 20)

    const sidebarWidth = document.querySelector('.pcOnly.left-content').offsetWidth
    const headerHeight = document.querySelector('.breadcrumb-container').offsetHeight
    const mouseX = e.clientX
    const mouseY = e.clientY

    // Tooltipの右端がウィンドウの右端を超える場合はTooltipを左側に表示
    if (mouseX + TOOLTIP_OFFSET + TOOLTIP_WIDTH > window.innerWidth) {
      tooltip.style.left = `${mouseX - sidebarWidth - TOOLTIP_WIDTH - TOOLTIP_OFFSET}px`
    } else {
      tooltip.style.left = `${mouseX - sidebarWidth + TOOLTIP_OFFSET}px`
    }

    tooltip.style.top = `${window.scrollY + mouseY - headerHeight - TOOLTIP_OFFSET}px`
    tooltip.innerHTML = targetCount === 0 ? '<div>No Data</div>' : '<div>Loading...</div>'
    tooltip.style.display = 'flex'

    if (targetCount === 0) {
      return
    }

    this.showTimeout = setTimeout(() => {
      this.executeAction(e)
    }, 1000)
  }

  hoverEnd(e) {
    e.preventDefault()

    clearTimeout(this.showTimeout)

    this.hideTimeout = setTimeout(() => {
      const tooltip = document.querySelector('.summaries__tooltip')
      tooltip.style.display = 'none'
    }, 200)
  }

  executeAction(event) {
    const dataset = event.target.dataset

    fetch(`/api/v1/entries?date=${dataset.date}&event_type=${dataset.eventType}`)
      .then(response => response.json())
      .then(data => {
        const content = data.map(entry => {
          const date = new Date(entry.event_date)
          return `<div class='entry'>
            <div class='entry_type'>${entry.facility_type}</div>
            <div class='entry_name'>${entry.daycare_center_name}</div>
            <div class='entry_time'>
              ${date.getHours().toString().padStart(2, '0')}:
              ${date.getMinutes().toString().padStart(2, '0')}
            </div>
          </div>`
        }).join('<hr>')

        const tooltip = document.querySelector('.summaries__tooltip')
        tooltip.innerHTML = `<div class='wrapper'><div class='description'>※最新の20件のみ表示</div><hr>${content}<hr></div>`

        // tooltip内容代入後にwindowの下端を超える場合は、Tooltipの下端を画面の下端に合わせる
        const windowsHeight = window.innerHeight
        const tooltipHeight = tooltip.offsetHeight
        if (event.clientY + tooltipHeight > windowsHeight - TOOLTIP_OFFSET) {
          const headerHeight = document.querySelector('.breadcrumb-container').offsetHeight
          const tooltipTop = window.scrollY + windowsHeight - headerHeight - tooltipHeight - TOOLTIP_OFFSET
          tooltip.style.top = `${tooltipTop}px`

          // tooltip内においてscrollが発生する場合になぜかtooltip内の要素の上端が見切れるので、margin-topで調整
          const wrapper = document.querySelector('.summaries__tooltip .wrapper')
          const wrapperHeight = wrapper.offsetHeight + 24 // 上下のpadding分足しておく
          if (tooltipHeight < wrapperHeight) {
            wrapper.style.marginTop = `${wrapperHeight - tooltipHeight}px`
          }
        }
      })
      .catch(error => {
        console.log(error)
        const tooltip = document.querySelector('.summaries__tooltip')
        tooltip.innerHTML = '<div>Error loading data</div>'
      })
  }

  onTooltipEnter(e) {
    e.preventDefault()
    clearTimeout(this.hideTimeout)
  }

  onTooltipLeave(e) {
    e.preventDefault()
    this.hoverEnd(e)
  }
}
