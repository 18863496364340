/**
 * check string is katakana
 * @param {*} kana_string String to check KataKana format
 * @returns KataKana format / empty string: undefined, invalid format: false, valid format: true
 */
export function validKataKanaFormat(kana_string) {
  if (!kana_string) {
    return undefined;
  }

  const kanaPattern = /^[\u30A0-\u30FF\sー 　]+$/;

  return kanaPattern.test(kana_string);
}

/**
 * check string is Hirakana
 * @param {*} kana_string String to check HiraKana format
 * @returns HiraKana format / empty string: undefined, invalid format: false, valid format: true
 */
export function validHiraKanaFormat(kana_string) {
  if (!kana_string) {
    return undefined;
  }

  // ひらがな、ー、半角スペース、全角スペースを許容
  const kanaPattern = /^[\u3040-\u309Fー 　]+$/;

  return kanaPattern.test(kana_string);
}

/**
 * check email is valid
 * @param {*} email email to check
 * @returns undeinfed / empty: undeinfed, valid: true, invalid: false
 */
export function validateEmail(email) {
  if (!email) {
    return undefined;
  }

  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  return emailPattern.test(email);
}

/**
 * check phone is valid format
 * ex: 0850-112-4567, 1234567890
 * @param {*} phone phone number to check
 * @returns undeinfed / empty: undefined, valid: true, invalid: false
 */
export function validatePhone(phone) {
  if (!phone) {
    return undefined;
  }

  const phonePattern = /^(\d{10,11}|\d{2,4}-\d{2,4}-\d{3,4})$/;

  return phonePattern.test(phone);
}

/**
 * check birth is valid format and date
 * @param {*} birth birth to check
 * @returns undefined/empty: undefined, valid: "Valid date", invalid: "Invalid date", future: "Future date"
 */
export function validateBirthday(birth) {
  if (!birth) {
    return undefined;
  }

  const birthday = new Date(birth);
  const today = new Date();

  if (isNaN(birthday.getTime())) {
    return "Invalid date";
  } else if (birthday > today) {
    return "Future date";
  } else {
    return "Valid date";
  }
}

/**
 * check password is valid
 * @param {*} password password to check
 * @returns undeinfed/empty: undefined, valid: true, invalid: false
 */
export function validPasswordFormat(password) {
  if (!password) {
    return undefined;
  }

  const passwordPattern = /^[A-Za-z0-9]{8,}$/;

  return passwordPattern.test(password);
}