import { Amplify } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';

// AWS Amplifyの設定を行います※クライアントサイドの初期化処理で実行をお願いいたします
const setupAWSAmplify = (
  REGION,
  USER_POOL_ID,
  AWS_COGNITO_APP_CLIENT_ID,
  AWS_COGNITO_URL,
  OAUTH_REDIRECT_SIGNIN,
  OAUTH_REDIRECT_SIGNOUT,
) => {
  Amplify.configure({
    Auth: {
      region: REGION,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: AWS_COGNITO_APP_CLIENT_ID,
    },
    oauth: {
      domain: AWS_COGNITO_URL,
      scope: ['profile', 'email', 'openid', 'phone', 'aws.cognito.signin.user.admin'],
      redirectSignIn: OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: OAUTH_REDIRECT_SIGNOUT,
      responseType: 'code',
    },
  });
};

// ログイン処理を行います
const signIn = async (username, password) => {
  return await Auth.signIn(username, password)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return { success: false, error: error }
    });
};

// SNS連携ログイン処理を行います
const federatedSignIn = async (options) => {
  return await Auth.federatedSignIn(options)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return { success: false, error: error }
    });
};

// ログアウト処理を行います
const signOut = async () => {
  return await Auth.signOut()
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return { success: false, error: error }
    });
};

// 新規登録処理を行います
const signUp = async (username, email, password, additionalAttributes = {}) => {
  return await Auth.signUp({ username, password, attributes: {
        email,
        ...additionalAttributes,
      }
    })
    .then((user) => {
      return { success: true, error: null, user: user };
    })
    .catch(error => {
      console.error(error);
      return { success: false, error: error, user: null }
    });
}

// 新規登録時にcognitoより送信される会員登録メールに記載される、検証コードの確認処理を行います
const signUpConfirm = async (username, verificationCode) => {
  return await Auth.confirmSignUp(username, verificationCode)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return {
        success: error.message === 'User cannot be confirmed. Current status is CONFIRMED', // 既に認証されている場合は成功扱いとします
        error: error,
      }
    });
}

// パスワードの再設定メールを送信します
const forgotPassword = async (username) => {
  return await Auth.forgotPassword(username)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return {
        success: false,
        error: error,
      }
    });
};

// パスワードの再設定メールに記載された検証コードによる、再設定を行います
const forgotPasswordConfirm = async (username, password, verificationCode) => {
  return await Auth.forgotPasswordSubmit(username, verificationCode, password)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return {
        success: false,
        error: error,
      }
    });
};

// パスワードを変更します※サインイン後に利用します
const changePassword = async (user, oldPassword, newPassword) => {
  return await Auth.changePassword(user, oldPassword, newPassword)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return { success: false, error: error }
    });
};

// ユーザ属性の検証を行います
const verifyCurrentUserAttributeSubmit = async (attributeName, verificationCode) => {
  return await Auth.verifyCurrentUserAttributeSubmit(attributeName, verificationCode)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return { success: false, error: error }
    });
};

// 会員登録メールの再送信を行います※検証コードの有効期限が切れた場合に利用します
const resendVerificationCode = async (username) => {
  return await Auth.resendSignUp(username)
    .then(() => {
      return { success: true, error: null };
    })
    .catch(error => {
      console.error(error);
      return { success: false, error: error }
    });
};

// 現在ログインしているユーザ情報を取得します
const getCurrentUser = async (isSuppressErrors) => {
  return await Auth.currentAuthenticatedUser()
    .then((user) => {
      return {
        success: true,
        error: null,
        data: user,
      };
    })
    .catch(error => {
      // 取得チェックにて未ログイン時にエラーを経由する場合、正常系のためエラー出力を省略します
      if (!isSuppressErrors) console.error(error);
      return { success: false, error: error, data: null }
    });
};

// 現在ログインしているユーザのセッション情報を取得します※getCurrentUserの変形版です。基本的にはgetCurrentUserにて事足ります
const getCurrentSession = async (isSuppressErrors) => {
  const currentSession = await Auth.currentSession()
    .then((userSession) => {
      return {
        success: true,
        error: null,
        data: userSession,
      };
    })
    .catch(error => {
      if (!isSuppressErrors) console.error(error);
      return { success: false, error: error, data: null }
    });
  
  if (!currentSession.success) return currentSession;

  // currentUserが取得できる場合は、refresh後のセッションを返却します
  const currentUser = await getCurrentUser(true);

  // 取得できなかった場合は、元のセッションを返却します
  if (!currentUser.success) return currentSession;

  const refreshedSession = await new Promise((resolve, reject) => {
    try {
      currentUser.data.refreshSession(
        currentSession.data.refreshToken,
        (err, session) => {
          if (err) {
            console.log('Could not refresh session', err);
            reject({
              success: false,
              error: err,
              data: null,
            });
          } else {
            console.log('Refreshed session', session)
            resolve({
              success: true,
              error: null,
              data: session,
            });
          }
        }
      );
    } catch (err) {
      console.log('Could not try refresh session', err);
      reject({
        success: false,
        error: err,
        data: null,
      });
    }
  });

  return refreshedSession;
};

// 現在ログインしているユーザのアクセストークンを作成します※基本的には、headers['Authorization']に設定してサーバーサイドに送り、サーバーサイドで検証を行うために利用します
const getCurrentToken = async (isSuppressErrors) => {
  const currentSession = await getCurrentSession(isSuppressErrors);
  
  if (currentSession.success) {
    return {
      success: true,
      error: null,
      data: {
        idToken: currentSession.data.getIdToken().jwtToken,
        accessToken: currentSession.data.getAccessToken().jwtToken,
        refreshToken: currentSession.data.getRefreshToken().token,
      },
    };
  } else {
    return {
      success: false,
      error: currentSession.error,
      data: null,
    };
  }
};

// 現在ログインしているかどうかを確認します
const checkIsSignedIn = async () => {
  const result = await getCurrentUser(true);
  return result.success;
};


export default {
  setupAWSAmplify,
  signIn,
  federatedSignIn,
  signOut,
  signUp,
  signUpConfirm,
  resendVerificationCode,
  forgotPassword,
  forgotPasswordConfirm,
  changePassword,
  verifyCurrentUserAttributeSubmit,
  getCurrentToken,
  getCurrentUser,
  getCurrentSession,
  checkIsSignedIn,
};
