import { Controller } from "@hotwired/stimulus"
import { formatCongnitoErrorMessage } from "../member/member_shared"

// Connects to data-controller="mypage--password-form-modal"
export default class extends Controller {
  
  // ログイン後のパスワード変更フォームの送信時の処理です
  async handleAccountsChangePasswordFormSubmit (e) {
    e.preventDefault();
    // ボタンを不活性化します※複数回押下されることを防ぐため
    const submitBtn = $(e.target.querySelector('input[type="submit"]'));
    submitBtn.attr('disabled', true);

    // eからPOSTのbodyを取得します
    const formData = new FormData(e.target);
    const currentPassword = formData.get('member[current_password]');
    const password = formData.get('member[password]');
    const passwordConfirmation = formData.get('member[password_confirmation]');

    // 念の為こちらでもチェックします
    const errorMessages = [];
    if (!currentPassword) {
      errorMessages.push('現在のパスワードを入力してください');
    }
    if (!password) {
      errorMessages.push('新しいパスワードを入力してください');
    }
    if (!passwordConfirmation) {
      errorMessages.push('確認用パスワードを入力してください');
    }
    if (password !== passwordConfirmation) {
      errorMessages.push('確認用パスワードが一致しません');
    }
    if (errorMessages.length > 0) {
      this.renderErrorMessages(errorMessages);
      return;
    }

    // 現在のユーザ情報を取得します
    const getUserResult = await window.auth.getCurrentUser();
    if (!getUserResult.success) {
      // エラーの場合
      console.log(getUserResult.error.message);
      this.renderErrorMessages(['本機能の利用には再ログインが必要です。一度ログアウトしてから再度お試しください。']);

      // ボタンの不活性化を解除します
      submitBtn.attr('disabled', false);
      return;
    }

    // cognitoのパスワードを変更します
    const result = await window.auth.changePassword(getUserResult.data, currentPassword, password);
    if (result.success) {
      // 再設定完了メッセージを表示します
      this.renderMessage('パスワード変更が完了いたしました');

      // ボタンの不活性化を解除します
      submitBtn.attr('disabled', false);

      // フォームの入力欄をクリアします
      e.target.querySelector('input[name="member[current_password]"]').value = '';
      e.target.querySelector('input[name="member[password]"]').value = '';
      e.target.querySelector('input[name="member[password_confirmation]"]').value = '';
    } else {
      // エラーの場合
      console.log(result.error.message);
      const cognitoErrorMessage = result.error.message === 'Incorrect username or password.'
        ? 'ご入力いただいた現在のパスワードが異なります'
        : formatCongnitoErrorMessage(result.error);
      this.renderErrorMessages([cognitoErrorMessage]);

      // ボタンの不活性化を解除します
      submitBtn.attr('disabled', false);
    }
  }

  // エラーメッセージの表示を行います
  renderErrorMessages (errorMessages) {
    // 既存のエラーメッセージを削除します
    $('#accountsChangePasswordFormMessage').remove();

    $('#accountsChangePasswordForm .accountsFormModalContainerFormWrap').prepend(`<ul id="accountsChangePasswordFormMessage">
      ${
        errorMessages.map((errorMessage) => `<li class="errorMessage">${ errorMessage }</li>`).join('')
      }
    </ul>`);
  }

  // 完了メッセージなどを表示します
  renderMessage (message) {
    // 既存のメッセージを削除します
    $('#accountsChangePasswordFormMessage').remove();

    $('#accountsChangePasswordForm .accountsFormModalContainerFormWrap').prepend(`<ul id="accountsChangePasswordFormMessage">
      <li>${ message }</li>
    </ul>`);
  }
}
