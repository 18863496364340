import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="app"
export default class extends Controller {
  connect () {
    const awsRegion = this.data.get('awsRegion');
    const awsCognitoUserPoolId = this.data.get('awsCognitoUserPoolId');
    const awsCognitoAppClientId = this.data.get('awsCognitoAppClientId');
    const awsCognitoUrl = this.data.get('awsCognitoUrl');

    // AWS Amplifyの初期化
    window.auth.setupAWSAmplify(
      awsRegion,
      awsCognitoUserPoolId,
      awsCognitoAppClientId,
      awsCognitoUrl,
      `${ window.location.origin }/oauth/redirected/signin`,
      `${ window.location.origin }/oauth/redirected/signout`,
    );
    console.log('AWS Amplify has been initialized.');

    this.checkAutoSignOutFlg();
  }

  // ログアウト処理
  checkAutoSignOutFlg () {
    // もしauto_sign_out=trueがクエリーパラメータに含まれている場合、rails側でのsession_controllerのdestroyが実行された後と判断し、フロント側もログアウト処理を行います
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const autoSignOut = urlParams.get('auto_sign_out');
      if (autoSignOut === 'true') {
        window.auth.signOut();
        console.log('Sign out by auto sign out.')
      }
    } catch (err) {
      console.log('Could not check auto sign out.', err);
    }
  }
}
