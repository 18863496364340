import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap";

// Connects to data-controller="toast"
export default class extends Controller {
  connect() {
    const toastElList = document.querySelectorAll('.toast');
    toastElList.forEach((toastEl) => new Toast(toastEl, { delay: 5000 }).show());
  }
}
