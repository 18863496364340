import { Controller } from "@hotwired/stimulus"
import { formatCongnitoErrorMessage, fetchToCheckEmailUniqueness } from "./member_shared"
import { v4 as uuid } from 'uuid'

// Connects to data-controller="member--signup"
export default class extends Controller {
  connect () {
    const { step } = this.element.dataset;

    switch (step) {
      case 'oauth_redirected':
        this.handleOAuthRedirect();
        break;
    }
  }

  // メールアドレスによる新規会員登録の送信時の処理です
  async handleSignUpFormSubmit (e) {
    e.preventDefault();
    // ボタンを不活性化します※複数回押下されることを防ぐため
    const submitBtn = $(e.target.querySelector('input[type="submit"]'));
    submitBtn.attr('disabled', true);

    // eからPOSTのbodyを取得します
    const formData = new FormData(e.target);
    const userCode = `uid_${ uuid() }`;
    const email = formData.get('member_signup_form[email]');
    const password = formData.get('member_signup_form[password]');

    // 未入力の場合はボタンが不活性化されておりますが(pages/register)、念の為こちらでもチェックします
    const errorMessages = [];
    if (!email) {
      errorMessages.push('メールアドレスを入力してください');
    }
    if (!password || password.length < 8) {
      errorMessages.push('8文字以上のパスワードを入力してください');
    }
    if (errorMessages.length > 0) {
      submitBtn.attr('disabled', false);
      this.renderErrorMessages(errorMessages);
      return;
    }

    // メールアドレスが既に登録されているか確認します
    const checkResult = await fetchToCheckEmailUniqueness(email);
    if (checkResult.isUnique === false) {
      if (checkResult.isVerified) {
        // もし検証済みで重複している場合、メールアドレス検証処理でエラーとなるため、事前にエラーメッセージを表示し、他のメールアドレス入力を促します
        this.renderErrorMessages([checkResult.errorMessage]);
      } else {
        // 重複しているユーザーが未検証状態の場合は、cognitoの会員登録を行おうとしたが、検証リンクをクリックしていない場合のため、検証コードを再送します
        const resendVerificationResult = await window.auth.resendVerificationCode(checkResult.duplicatedUserCode);
        if (resendVerificationResult.success) {
          // 再送した場合も、検証メール送信済み画面を表示します
          this.switchToCognitoVerificationMailHasBeenSent();
        } else {
          const cognitoErrorMessage = formatCongnitoErrorMessage(resendVerificationResult.error);
          this.renderErrorMessages([cognitoErrorMessage]);
        }
      }

      submitBtn.attr('disabled', false);
      return;
    }

    // cognitoにサインアップします
    const result = await window.auth.signUp(userCode, email, password);
    if (result.success) {
      // 検証用のリンクがメール送信されるため、その旨を表示します
      this.switchToCognitoVerificationMailHasBeenSent();
    } else {
      // エラーの場合
      console.log(result.error.message);
      if (result.error.message === 'User already exists' || result.error.message === 'PreSignUp failed with error Email is already in use. Please use another email address..') {
        // 本エラーの場合、会員登録済みのユーザーが存在しているため、その旨を表示します
        this.renderErrorMessages(['ご入力いただきましたメールアドレスは既に登録されております。']);
      } else {
        const cognitoErrorMessage = formatCongnitoErrorMessage(result.error);
        this.renderErrorMessages([cognitoErrorMessage]);

        // ボタンの不活性化を解除します
        submitBtn.attr('disabled', false);
      }
    }
  }

  // LINE新規会員登録ボタンのクリック時の処理です
  async handleLineSignUpButtonClick (e) {
    window.auth.federatedSignIn({ customProvider: 'LINE'});
  }

  // LINEのリダイレクト後の画面からのボタンのクリック時の処理です
  async handleLineReSignInButtonClick (e) {
    window.auth.federatedSignIn({ customProvider: 'LINE'});
  }

  // LINEのOAuth許可が行われ、リダイレクトしてきた際の処理です
  async handleOAuthRedirect () {
    const handleFailure = (hiddenMessage = null) => {
      document.querySelector('#line-action-message').classList.add('c-hidden');
      document.querySelector('#error-message-container').classList.remove('c-hidden');

      // モバイル端末でのデバッグ用の隠されたエラー文を格納します
      document.querySelector('#hidden-error-message-container').innerText += hiddenMessage;
    };

    try {
      const { actionName } = this.element.dataset;

      switch (actionName) {
        case 'signin':
          // サインインが成功したかどうか、判定します
          await window.auth.getCurrentToken()
            .then(async (result) => {
              if (result.success) {
                // 成功時は、メールアドレスによるサインイン同様にrails側のsessionに保存します
                // LINE連携には、サインアップとサインインの区別が無いため、サインイン時にもこちらの処理を通ります
                // cognitoのトークンを設定し、ログインフォームの情報を送信します
                const signInFormForOauthRedirected = document.querySelector('#signInFormForOauthRedirected');
                $('#login_form_cognito_token').val(result.data.accessToken);
                $('#login_form_cognito_refresh_token').val(result.data.refreshToken);
                signInFormForOauthRedirected.submit();
              } else {
                // 取得できなかった場合は、再度サインインを実行します(その結果トークン取得が行える場合はそのままログイン、行えない場合はLINEアプリを開く挙動となります)
                // ※LINEアプリ側で拒否してリダイレクトしてきた場合は、再度LINEアプリが開いてしまいますが、そちらは仕様として無視することとなりました(2024/06/28)
                this.handleLineReSignInButtonClick();
              }
            });
          break;
        case 'signout':
          // ルート画面へ戻ります
          window.location.href = '/';
          break;
        default:
          // 未知のアクション
          handleFailure();
          break;
      }
    } catch (error) {
      console.log('handleOAuthRedirectError', error);
      handleFailure(JSON.stringify(error));
    }
  }

  // 開発コンソールの使えないモバイル端末でのLINEブラウザなどの、デバッグ用の隠されたエラー文を表示します※10回タップすると背景色と同じ色にて表示されます
  handleHiddenErrorMessageContainerVisibleCount () {
    const hiddenErrorMessageContainer = document.querySelector('#hidden-error-message-container');
    const currentCount = parseInt(hiddenErrorMessageContainer.dataset.count) || 0;
    hiddenErrorMessageContainer.dataset.count = currentCount + 1;
    console.log(currentCount);

    if (currentCount >= 10) {
      hiddenErrorMessageContainer.classList.remove('c-hidden');
    }
  }

  // エラーメッセージの表示を行います
  renderErrorMessages (errorMessages) {
    // 既存のエラーメッセージを削除します
    $('#errorMessagesUl').remove();

    $('.signInMethodsDivider').after(`<ul id="errorMessagesUl">${
      errorMessages.map((errorMessage) => `<li class="errorMessage">${ errorMessage }</li>`).join('')
    }</ul>`);
  }

  // 会員登録画面のSP版表示の場合に、初期表示されているSwiperを非表示にするメソッドです
  hideSwiperWhenMd () {
    const swiperContainerWrapperBackground = document.querySelector('.swiperContainerWrapperBackground');
    if (swiperContainerWrapperBackground) {
      swiperContainerWrapperBackground.classList.add('hiddenOnlyMd');
    }
  }

  // cognitoの検証コード送信済み表示に切り替えます
  switchToCognitoVerificationMailHasBeenSent () {
    // 会員登録フォームを非表示にします
    $('#signUpForm').addClass('hidden');
    $('#cognitoStepVerificationMailHasBeenSent').removeClass('hidden');
  }
}
