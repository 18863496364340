export default function geocoding(pref, city, street) {
  const address = `${pref}${city}${street}`;
  const params = new URLSearchParams({ address })
  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(`/api/v1/geocoding?${params}`, options).then(async (response) => {
    if (!response.ok) {
      return null;
    }
    return await response.json();
  });
}
