import { Controller } from "@hotwired/stimulus"
import { formatCongnitoErrorMessage } from "./member_shared"

// Connects to data-controller="member--password-reset"
export default class extends Controller {
  // フォーム要素の挙動をcognito向けに変更します
  async handlePasswordResetFormSubmit (e) {
    e.preventDefault();
    // ボタンを不活性化します※複数回押下されることを防ぐため
    const submitBtn = $(e.target.querySelector('input[type="submit"]'));
    submitBtn.attr('disabled', true);

    // eからPOSTのbodyを取得します
    const formData = new FormData(e.target);
    const email = formData.get('reset_form[email]');

    // 未入力の場合はrequired属性により別途止められておりますが、念の為こちらでもチェックします
    const errorMessages = [];
    if (!email) {
      errorMessages.push('メールアドレスを入力してください');
    }
    if (errorMessages.length > 0) {
      this.renderErrorMessages(errorMessages);
      return;
    }

    const userCode = email;

    // cognitoのパスワードリセットを試行します
    const result = await window.auth.forgotPassword(userCode);

    if (result.success) {
      // 再設定ページへのリンクがメール送信されるため、その旨を表示します
      this.switchToCognitoVerificationMailHasBeenSent();
    } else {
      // エラーの場合
      console.log(result.error.message);
      const cognitoErrorMessage = formatCongnitoErrorMessage(result.error);
      this.renderErrorMessages([cognitoErrorMessage]);

      // ボタンの不活性化を解除します
      submitBtn.attr('disabled', false);
    }
  }

  // パスワードの設定ボタンの処理です
  async handlePasswordEditSubmit (e) {
    e.preventDefault();
    
    // ボタンを不活性化します※複数回押下されることを防ぐため
    const submitBtn = $(e.target.querySelector('input[type="submit"]'));
    submitBtn.attr('disabled', true);

    // eからPOSTのbodyを取得します
    const formData = new FormData(e.target);
    const user_code = formData.get('user_code');
    const verification_code = formData.get('verification_code');
    const password = formData.get('password');
    const password_confirmation = formData.get('password_confirmation');

    // バリデーションは'pages/reset_pass'にて行われておりますが、念の為こちらでもチェックします
    const errorMessages = [];
    if (!user_code) {
      errorMessages.push('対象のユーザーがありません。再度お手続きをお願いいたします');
    }
    if (!verification_code) {
      errorMessages.push('検証コードがありません。再度お手続きをお願いいたします');
    }
    if (!password || password.length < 8) {
      errorMessages.push('8文字以上のパスワードを入力してください');
    }
    if (password !== password_confirmation) {
      errorMessages.push('パスワードが一致しません');
    }
    if (errorMessages.length > 0) {
      this.renderErrorMessages(errorMessages);
      return;
    }

    // cognitoのパスワード再設定を行います
    const result = await window.auth.forgotPasswordConfirm(user_code, password, verification_code);
    if (result.success) {
      // 再設定完了画面を表示します
      this.switchToCognitoPasswordHasBeenReset();
    } else {
      // エラーの場合
      console.log(result.error.message);
      const cognitoErrorMessage = formatCongnitoErrorMessage(result.error);
      this.renderErrorMessages([cognitoErrorMessage]);

      // ボタンの不活性化を解除します
      submitBtn.attr('disabled', false);
    }
  }

  // エラーメッセージの表示を行います
  renderErrorMessages (errorMessages) {
    // 既存のエラーメッセージを削除します
    $('#errorMessagesUl').remove();

    $('form').before(`<ul id="errorMessagesUl">${
      errorMessages.map((errorMessage) => `<li class="errorMessage">${ errorMessage }</li>`).join('')
    }</ul>`);
  }

  // cognitoの検証コード送信済み表示に切り替えます
  switchToCognitoVerificationMailHasBeenSent () {
    $('.rightContainer > .wrapper').addClass('hidden');
    $('#cognitoStepVerificationMailHasBeenSent').removeClass('hidden');
  }

  // cognitoのパスワード再設定済み表示に切り替えます
  switchToCognitoPasswordHasBeenReset () {
    $('.rightContainer > .wrapper').addClass('hidden');
    $('#cognitoStepPasswordHasBeenReset').removeClass('hidden');
  }
}
