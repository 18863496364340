import { Controller } from "@hotwired/stimulus"
import { formatCongnitoErrorMessage, fetchToCheckEmailUniqueness } from "./member_shared"

// Connects to data-controller="member--signin"
export default class extends Controller {
  connect () {
    this.checkAutoLineSignInFlg();
  }

  // サインインフォームの送信時の処理です
  async handleSignInFormSubmit (e) {
    e.preventDefault();
    // ボタンを不活性化します※複数回押下されることを防ぐため
    const submitBtn = $(e.target.querySelector('input[type="submit"]'));
    submitBtn.attr('disabled', true);

    // eからPOSTのbodyを取得します
    const formData = new FormData(e.target);
    const email = formData.get('login_form[login_info]');
    const password = formData.get('login_form[password]');

    // 未入力の場合はボタンが不活性化されておりますが(pages/login)、念の為こちらでもチェックします
    const errorMessages = [];
    if (!email) {
      errorMessages.push('メールアドレスを入力してください');
    }
    if (!password) {
      errorMessages.push('パスワードを入力してください');
    }
    if (errorMessages.length > 0) {
      this.renderErrorMessages(errorMessages);
      return;
    }

    const userCode = email;

    // cognitoにサインインします
    const result = await window.auth.signIn(userCode, password);
    if (result.success) {
      // サインイン成功の場合、rails側のログイン処理を行います
      await window.auth.getCurrentToken()
      .then((result) => {
        if (result.success) {
          // cognitoのトークンを設定し、ログインフォームの情報を送信します
          $('#login_form_cognito_token').val(result.data.accessToken);
          $('#login_form_cognito_refresh_token').val(result.data.refreshToken);
          e.target.submit();
        } else {
          // こちらの処理には到達しない想定です
          this.renderErrorMessages(['認証トークンの生成に失敗しました。時間を置いてから再度お試しください。']);
        }
      });
    } else {
      // エラーの場合
      console.log(result.error.message);

      // 失敗時には単純にユーザー名・パスワード間違いの場合と、新規会員登録が未完了でメールアドレス未検証の場合があり、後者を検証するために、まず未検証の重複ユーザーコードが存在するかどうかを確認します
      const checkResult = await fetchToCheckEmailUniqueness(email);
      if (checkResult.duplicatedUserCode) {
        const resendVerificationResult = await window.auth.resendVerificationCode(checkResult.duplicatedUserCode);
        if (resendVerificationResult.success) {
          // 再送した場合は、その旨をメッセージ表示します
          this.renderErrorMessages(["メールアドレスの検証用リンクを再送いたしました。メールに記載されたリンクをクリックして、メールアドレスの検証をご完了ください。"]);
        } else {
          const cognitoErrorMessage = formatCongnitoErrorMessage(resendVerificationResult.error);
          this.renderErrorMessages([cognitoErrorMessage]);
        }
      } else {
        // 通常のエラーの場合
        const cognitoErrorMessage = formatCongnitoErrorMessage(result.error);
        this.renderErrorMessages([cognitoErrorMessage]);
        this.openLoginMigrationNoticeModal();
      }

      // ボタンの不活性化を解除します
      submitBtn.attr('disabled', false);
    }
  }

  // LINEログインボタンの処理です
  async handleLineSignInButtonClick (e) {
    window.auth.federatedSignIn({ customProvider: 'LINE'});
  }

  // エラーメッセージの表示を行います
  renderErrorMessages (errorMessages) {
    // 既存のエラーメッセージを削除します
    $('#errorMessagesUl').remove();

    $('.signInMethodsDivider').after(`<ul id="errorMessagesUl">${
      errorMessages.map((errorMessage) => `<li class="errorMessage">${ errorMessage }</li>`).join('')
    }</ul>`);
  }

  // ログイン移管のパスワード再設定を促すモーダルを表示します
  openLoginMigrationNoticeModal () {
    $('#loginMigrationNoticeModal').removeClass('c-hidden');
  }

  // ログイン移管モーダルを閉じます
  handleLoginMigrationNoticeModalCloseClick (e) {
    $('#loginMigrationNoticeModal').addClass('c-hidden');
  }

  // 自動的なログイン処理
  checkAutoLineSignInFlg () {
    // 仕様 友達追加時メッセージがLINEに送信され、そのメッセージのURLをタップした場合に、ログイン画面を開き、自動的にLINE連携ログインを発火する
    // もしauto_line_sign_in=trueがクエリーパラメータに含まれている場合に処理を行います
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const autoLineSignIn = urlParams.get('auto_line_sign_in');
      if (autoLineSignIn === 'true') {
        // ログインボタンを押されたものとして処理を行います
        this.handleLineSignInButtonClick();
        console.log('Sign in by auto line sign in.')
      }
    } catch (err) {
      console.log('Could not check auto line sign in.', err);
    }
  }
}
