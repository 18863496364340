import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard"
export default class extends Controller {

  static targets = ['csvDownload'];
  connect() {
    this.csvDownloadTarget.addEventListener('click', (e) => {
      e.preventDefault();
      const csvUrlStr = `${location.origin}${location.pathname}.csv${location.search}`;
      location.href = csvUrlStr;
    });
  }
}
