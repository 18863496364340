import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cities-select"
export default class extends Controller {
  connect() {
    const parentSelect = document.querySelector('.sel_prefecture');

    this.loadCities(parentSelect);
    parentSelect.addEventListener('change', () => this.loadCities(parentSelect));
  }

  loadCities(parentSelect) {
    const prefectureIds = Array.from(parentSelect.selectedOptions).map((option) => {
      return option.value;
    });
    fetch(`/api/v1/cities_select?prefecture_ids=${prefectureIds.join(",")}`)
    .then(res => res.json())
    .then(options => {
      let params = new URLSearchParams(window.location.search);
      let city_ids = params.getAll('q[cities][]');
      
      const childSelect = document.querySelector('.sel_city');
      childSelect.innerHTML = '';
      options.data.forEach(option => { 
        var opt = document.createElement('option'); 
        opt.textContent = option.name;
        opt.value = option.id;
        if (city_ids.includes(option.id.toString())) {
          opt.selected = true;
        }
        childSelect.appendChild(opt); 
      });
    });
  };
}
