import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-cities"
export default class extends Controller {
  static targets = ['cities'];

  connect() {
    this.csrfToken = document.querySelector('meta[name=csrf-token]').content;
  }

  async changePref(e) {
    const citySelect = this.citiesTarget.querySelector('select');
    // optionクリア
    citySelect.innerHTML = '';
    // 最初の「市区町村」だけ追加しておく
    const firstCityOpt = document.createElement('option');
    firstCityOpt.value = '';
    firstCityOpt.text = '市区町村';
    citySelect.add(firstCityOpt);

    if (!e.target.value) {
      return;
    }

    const cities = await this._fetchCities(e.target.value);
    if (!cities) {
      return;
    }
    cities.forEach(([cityId, cityName]) => {
      const newOpt = document.createElement('option');
      newOpt.value = cityId;
      newOpt.text = cityName;
      citySelect.add(newOpt);
    });
  }

  async _fetchCities(prefId) {
    const fetchOptions = {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.csrfToken,
      }
    };
    const result = await fetch(`/cities?pref_id=${prefId}`, fetchOptions);
    if (!result.ok) {
      console.error({ result });
      return;
    }

    return await result.json();
  }
}
