import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="confirm-dialog"
export default class extends Controller {

  static targets = ['dialog', 'accept', 'cancel'];

  connect() {
    this.cancelTarget.addEventListener('click', (e) => {
      e.preventDefault();
      this.closeDialog();
    });
    this.acceptTarget.addEventListener('click', () => {
      this.closeDialog();
    });
  }

  closeDialog() {
    this.dialogTarget.classList.add('hide');
    this.dialogTarget.classList.remove('show');
    // this.dialogTarget.style.display = 'none';
  }
}
