import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  connect() {
  }

  change(e) {
    e.preventDefault()

    const input = event.target

    if (!input.files || input.files.length === 0) {
      return false;
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      const src = e.target.result
      this.updatePreview(src)
    }
    reader.readAsDataURL(input.files[0])
  }

  clear() {
    $(this.element).find('input[type="file"]').val("")
    this.updatePreview()
  }

  updatePreview(src) {
    const current = $(this.element).find('.image-preview img')
    if (current) {
      current.remove()
    }
    if (src) {
      const image = $('<img>').attr('src', src)
      $(this.element).find('.image-preview').append(image)
      $(this.element).find('.delete-flag').val(false)
    } else {
      $(this.element).find('.delete-flag').val(true)
    }
  }
}
