import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.target = $('.target').val()
    this.src = ""
    this.id = ""
  }

  selectImage(e) {
    e.preventDefault()
    $(".images-item").removeClass('selected')
    $(e.target).closest(".images-item").addClass('selected')
    this.src = e.target.src
    this.id = e.target.dataset.id
  }

  close() {
    const event = new CustomEvent('closemodal')
    window.dispatchEvent(event)
  }

  submit(e) {
    e.preventDefault()
    const event = new CustomEvent('selectedimage', {detail: {
      id: this.id,
      src: this.src,
      target: this.target
    }})
    window.dispatchEvent(event)
    this.close()
  }
}