import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="master-vendor"
export default class extends Controller {

  static targets = ['openDialog'];

  connect() {
    this.confirmDialog = document.querySelector('#confirmDialog');

    this.openDialogTarget.addEventListener('click', (e) => {
      e.preventDefault();

      this.confirmDialog.classList.add('show');
      this.confirmDialog.classList.remove('hide');
      this.confirmDialog.style.display = 'block';
    });
  }
}
