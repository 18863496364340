import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat-read-more"
export default class extends Controller {
  static targets = ['history', 'moreLink'];

  set h(h) {
    this._h = h;
  }

  get h() {
    return this._h || 0;
  }

  connect() {
    this.collapseMessages();

    // FIXME: turbo:render などで実行したい
    this.historyTarget.scrollTo(0, this.historyTarget.scrollHeight - this.h);
    this.h = this.historyTarget.scrollHeight;
  }

  /**
   * メール内容が3行を超える場合、メッセージ内容を省略表示する
   */
  collapseMessages() {
    document.querySelectorAll('.chat-body').forEach((chatBody) => {
      const messages = chatBody.innerHTML.split(/<br ?\/?>/i);
      if (messages.length <= 3 || chatBody.classList.contains('pass-hide')) {
        return;
      }
      chatBody.classList.add('pass-hide');

      const aTag = document.createElement('a');
      aTag.href = '#';
      aTag.addEventListener('click', (e) => {
        e.preventDefault();
        e.target.style.display = 'none';
        e.target.parentElement.querySelector('.hidden-message').style.display = 'block';
      });
      aTag.innerHTML = '<br>...全体を表示する';
      const collapsedMessage = messages.splice(0, 3).join('<br>');
      const defaultSpanTag = document.createElement('span');
      defaultSpanTag.innerHTML = collapsedMessage;

      const hiddenMessage = messages.join('<br>');
      const hiddenSpanTag = document.createElement('span');
      hiddenSpanTag.innerHTML = hiddenMessage;
      hiddenSpanTag.classList.add('hidden-message');
      hiddenSpanTag.style.display = 'none';

      chatBody.innerHTML = '';
      chatBody.insertAdjacentElement('beforeend', defaultSpanTag);
      chatBody.insertAdjacentElement('beforeend', hiddenSpanTag);
      chatBody.insertAdjacentElement('beforeend', aTag);
    });
  }
}
