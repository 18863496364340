import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="common--delete-confirm"
export default class extends Controller {
  
  connect() {
    this.element.children[0].addEventListener('click', this.open.bind(this))
    
    this.element.querySelector('.cancel').addEventListener('click', this.close.bind(this))
  }

  open(event) {
    this.element.querySelector('.confirmDialog').classList.add('show')
  }

  close(event) {
    this.element.querySelector('.confirmDialog').classList.remove('show')
  }
}
