import { Controller } from "@hotwired/stimulus"
import geocoding from "../../geocoding";

// Connects to data-controller="vendor--details-edit"
export default class extends Controller {
  static targets = ['pref', 'city', 'street', 'latitude', 'longitude'];

  connect() {
  }

  async getCoordinates(e) {
    e.preventDefault();

    const selectedPref = this.prefTarget.selectedOptions[0]?.label;
    const selectedCity = this.cityTarget.querySelector('select').selectedOptions[0]?.label;
    const { latitude, longitude } = await geocoding(selectedPref, selectedCity, this.streetTarget.value);
    this.latitudeTarget.value = latitude;
    this.longitudeTarget.value = longitude;
  }

  updateAge(e) {
    e.preventDefault();

    const checkboxes = this.element.querySelector('.vendors-detail__ages').querySelectorAll('.form-control[type="checkbox"]');
    let allUnchecked = true;

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        allUnchecked = false;
      }
    });

    const row = document.querySelector('.c-daycare-center').querySelector('.allowedAgeTable').querySelector('tbody').querySelector('tr');
    let innerHTML = '';
    if (allUnchecked) {
      innerHTML = '<td colspan="6" class="tel">お電話にてお問い合わせください。</td>'
    } else {
      checkboxes.forEach(checkbox => {
        innerHTML +=  checkbox.checked ? '<td><div class="allowedAgeTableOk"></div></td>' : '<td><div class="allowedAgeTableNgLeft"></div><div class="allowedAgeTableNgRight"></div></td>'
      });
    }
    row.innerHTML = innerHTML
  }

  updateAddress(e) {
    e.preventDefault();
    const selectElementPref = document.querySelector('.vendors-edit').querySelector('.address-select__pref');
    const selectedOptionPref = selectElementPref.options[selectElementPref.selectedIndex];
    const textPref = selectedOptionPref.text;

    const selectElementCity = document.querySelector('.vendors-edit').querySelector('.address-select__city');
    const selectedOptionCity = selectElementCity.options[selectElementCity.selectedIndex];
    const textCity = selectedOptionCity.text;
    
    const other = document.querySelector('.vendors-edit').querySelector('.address-select__other').value;
    const address = textPref + textCity + other;
    document.querySelector('.c-daycare-center').querySelector('.accssBoxAdressText').textContent = address;
    document.querySelector('.c-daycare-center').querySelector('.nurserySchoolAddress').textContent = address;
    document.querySelector('.c-daycare-center').querySelector('iframe').src = `https://maps.google.co.jp/maps?output=embed&q=${address}&z=16`
  }

  updateDaycareCenterType(e) {
    e.preventDefault();
      
    const selected = e.target.value;
    const selectedText = e.target.options[e.target.selectedIndex].text
    const category = document.querySelector('.c-daycare-center').querySelector('.category')
    category.classList = `category ${selected}`;
    category.textContent = selectedText
  }

  updateFacilityFeatures(e) {
    e.preventDefault();
    const { feature } = e.currentTarget.dataset;
    const index = e.currentTarget.value;
    const checked = e.currentTarget.checked;

    if (['tour', 'temporary', 'extend', 'pre_interview'].includes(feature)) {
      const featureBoxImg = document.querySelector('.c-daycare-center').querySelector(`.imgBox.img__${feature}`);
      const featureBox = featureBoxImg.parentElement;

      if (checked) {
        featureBox.classList.remove('facilityFeaturesBoxNg');
        featureBoxImg.classList.remove('ng');
        featureBoxImg.classList.add('ok');
      } else {
        featureBox.classList.add('facilityFeaturesBoxNg');
        featureBoxImg.classList.add('ng');
        featureBoxImg.classList.remove('ok');
      }
    }

    const featureElement = document.querySelector('.c-daycare-center').querySelector('.featureBoxFlex').querySelectorAll('.featureBoxFlexItem')[index];
    const featureImg = featureElement.querySelector('.imgBox');
    
    if (checked) {
      featureElement.classList.remove('featureBoxFlexItemNg');
      featureImg.classList.remove('ng');
      featureImg.classList.add('ok');
    } else {
      featureElement.classList.add('featureBoxFlexItemNg');
      featureImg.classList.remove('ok');
      featureImg.classList.add('ng');
    }
  }

  updateChildCareTime(e) {
    const { dayType, tableType, part } = e.currentTarget.dataset;
    const value = e.currentTarget.value;
    const dayTypeIndex = ['weekday', 'saturday', 'holiday'].indexOf(dayType);

    if (!['open', 'normal', 'short'].includes(tableType) || dayTypeIndex < 0) return;
    const targetTable = document.querySelector('.c-daycare-center').querySelector(`.${tableType}TimeTable`);
    const targetRow = targetTable.querySelector('tbody').querySelectorAll('tr')[dayTypeIndex];
    const target = targetRow.querySelectorAll('td')[1];
    const times = target.textContent.split(' ~ ')

    target.textContent = part === "start" ? `${value} ~ ${times[1]}` : `${times[0]} ~ ${value}`
  }

  updateText(e) {
    e.preventDefault();
      
    const value = e.target.value;
    const { target } = e.currentTarget.dataset;
    document.querySelector('.c-daycare-center').querySelector(`.${target}`).innerText = value;
  }

  selectImage(event) {
    event.preventDefault();

    const input = event.target
    const { index } = event.currentTarget.dataset;

    if (!input.files || input.files.length === 0 || !index) {
      return false;
    }

    // 画像の削除フラグをfalseにする
    document.querySelector(`#image_delete_field_${index}`).value = false;
    // ストック画像選択idを空にする
    const selectedEle = document.querySelector(`#image_selected_field_${index}`)
    if (selectedEle) {
      selectedEle.value = ""
    }

    // 表示の更新
    var reader = new FileReader()
    reader.onload = (e) => {
      const imgSrc = e.target.result;
      this._updatePreview(index, imgSrc)
    }
    reader.readAsDataURL(input.files[0])
  }

  deselectImage(event) {
    event.preventDefault();

    const { index } = event.currentTarget.dataset;
    if (!index) return false;

    const previewTarget = document.querySelector(`.image-preview-frame[data-index="${index}"]`);

    // 入力フォームの操作
    // 入力値を空にする
    let input = previewTarget.parentNode.querySelector('input');
    input.value = [];
    //  削除フラグを立てる
    document.querySelector(`#image_delete_field_${index}`).value = true;
    // ストック画像選択idを空にする
    const selectedEle = document.querySelector(`#image_selected_field_${index}`)
    if (selectedEle) {
      selectedEle.value = ""
    }
    this._updatePreview(index)
  }

  selectedDaycareCenterImage(e) {
    const {id, src, target} = e.detail
    if (!id || !src || !target) {
      return false
    }
    // input="file"を空にする
    const previewTarget = document.querySelector(`.image-preview-frame[data-index="${target}"]`);
    let input = previewTarget.parentNode.querySelector('input');
    input.value = [];
    // 画像の削除フラグをfalseにする
    document.querySelector(`#image_delete_field_${target}`).value = false;
    // ストック画像選択idをセットする
    document.querySelector(`#image_selected_field_${target}`).value = id;
    this._updatePreview(target, src)
  }

  _updatePreview(index, src) {
    // 選択画像表示ボックスの更新
    const previewTarget = document.querySelector(`.image-preview-frame[data-index="${index}"]`);
    let img = previewTarget.querySelector('img');
    if (src) {
      if (!img) {
        img = document.createElement('img');
        previewTarget.appendChild(img);
      }
      img.src = src;
    } else {
      img.remove();
    }

    // プレビューエリアの画像の更新
    if (index === 'main' || index.startsWith('sub')) {
        this._updateSwiperImage(index, src);
    } else if (index === 'principal') {
        this._updatePrincipalImage(src);
    }
  }

  _updateSwiperImage(index, img) {
    const swiper = this._getSwiper();
    
    // swiperの更新対象の取得
    const targetSlide = document.querySelector(`.swiper-image-${index}`);
    const targetIndex = this._getTargetSwiperSlideIndex(targetSlide, index);

    // 既存画像の削除
    if (targetSlide) swiper.removeSlide(targetIndex);

    // 新規画像がある場合は挿入
    if (img) {
      const swiperImg = document.createElement('img');
      swiperImg.src = img;
      swiperImg.classList.add('reportImage', 'swiper-slide', 'mainImageSwiperSlide', `swiper-image-${index}`);
      const swiperImgHtml = swiperImg.outerHTML;
      swiper.addSlide(targetIndex, swiperImgHtml);
    }

    swiper.update();
  }

  _getTargetSwiperSlideIndex(targetSlide, index) {
    if (index === 'main') return 0;
    if (targetSlide) return targetSlide.getAttribute('data-swiper-slide-index');

    // subの場合は、自分より若い番号のsub画像またはmaing画像の次に挿入する
    const indexNum = parseInt(index.replace('sub', ''), 10);
    for (let i = indexNum; i >= 0; i--) {
      const suffix = i === 0 ? 'main' : `sub${i}`;
      const targetSlide = document.querySelector(`.swiper-image-${suffix}`);
      if (targetSlide) {
        const targetIndex = targetSlide.getAttribute('data-swiper-slide-index');
        return parseInt(targetIndex, 10) + 1;
      }
    }
  }

  _updatePrincipalImage(img) {
    const teacherBox = document.querySelector('.c-daycare-center').querySelector('.teacherBoxArea');
    const imageBox = teacherBox.querySelector('.teacherBoxImg');
    let imageContainer = imageBox.querySelector('img');

    if (!img) {
      imageContainer?.remove();
    } else {
      if (!imageContainer) {
        imageContainer = document.createElement('img');
        imageBox.appendChild(imageContainer);
      }
      imageContainer.src = img
    }
  }

  _getSwiper() {
    const swiperController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("#daycareCenterSwiperImages"),
      "daycare-center-swiper"
    )
    return swiperController.swiper
  }
}
