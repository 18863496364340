import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="master-select-vendors-form"
export default class extends Controller {

  static targets = ['groups', 'daycareCenters'];
  static values = { page: Number, ended: Boolean, groupIds: Array };

  connect() {
    this.addScrollListener()
    this.changeGroups()
  }

  changeGroups() {
    this.groupIdsValue = Array.from(this.groupsTarget.selectedOptions).map((option) => {
      return option.value;
    });
    this.pageValue = 1;
    this.endedValue = false;
    this.daycareCentersTarget.scrollTop = 0;

    fetch(`/api/v1/vendors?group_ids=${this.groupIdsValue.join(",")}&page=${this.pageValue}`)
      .then(res => res.json())
      .then(options => {
        let params = new URLSearchParams(window.location.search);
        let daycare_center_ids = params.getAll('q[daycare_centers][]');

        this.daycareCentersTarget.innerHTML = '';
        options.data.forEach(option => {
          var opt = document.createElement('option');
          opt.textContent = option.name;
          opt.value = option.id;
          if (daycare_center_ids.includes(option.id.toString())) {
            opt.selected = true;
          }
          this.daycareCentersTarget.appendChild(opt);
        });
      });
  }

  addScrollListener() {
    this.daycareCentersTarget.addEventListener('scroll', () => {
      // セレクトボックスのスクロール位置が下端に近づいた場合にデータをロード
      if (this.daycareCentersTarget.scrollTop + this.daycareCentersTarget.offsetHeight >= this.daycareCentersTarget.scrollHeight) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    if (this.endedValue) return;

    this.pageValue += 1;
    const url = `/api/v1/vendors?group_ids=${this.groupIdsValue.join(",")}&page=${this.pageValue}`;

    fetch(url)
      .then(res => res.json())
      .then(json => {
        if (json.data.length === 0) {
          this.endedValue = true;
          return;
        }

        json.data.forEach(option => {
          var opt = document.createElement('option');
          opt.textContent = option.name;
          opt.value = option.id;
          this.daycareCentersTarget.appendChild(opt);
        });
      });
  }
}
