import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="common--summaries-csv"
export default class extends Controller {
  connect() {}

  detailCsvDownload(e) {
    e.preventDefault();
    this._csvDownload('0');
  }

  totalCsvDownload(e) {
    e.preventDefault();
    this._csvDownload('1');
  }

  _csvDownload(total) {
    const url = new URL(location);
    url.searchParams.set('total', total);
    location.href = `${url.origin}${url.pathname}.csv${url.search}`;
  }
}
