import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-daycare-centers"
export default class extends Controller {
  static targets = [
    'daycareCenterCount',
    'form',
    'area',
    'station',
    'name',
    'location',
    'lat',
    'lng'
  ];

  connect() {
    this.csrfToken = document.querySelector('meta[name=csrf-token]').content;
    this._getChange();
    this.hiddenClassName = 'hidden';
  }

  async onChange() {
    this._getChange();
  }

  tabClick(e) {
    e.preventDefault();

    let selectTab = e.target;
    if (!selectTab.classList.contains('tab')) {
      selectTab = selectTab.closest('.tab');
    }

    const tabs = selectTab.closest('.tabs');
    const isDataClear = tabs.dataset['clear'];
    this._resetTabSelect(tabs, isDataClear);
    selectTab.classList.add('selected')

    const displayTarget = selectTab.dataset['target'];
    if (!displayTarget) return;

    this._hideAllSearchItems();
    if (displayTarget === 'area') {
      this.areaTarget.classList.remove(this.hiddenClassName);
      if (this.locationTargets && this.locationTargets[0]) {
        this.locationTargets[0].classList.remove(this.hiddenClassName);
      }
    } else if (displayTarget === 'station') {
      this.stationTarget.classList.remove(this.hiddenClassName);
    } else if (displayTarget === 'name') {
      this.nameTarget.classList.remove(this.hiddenClassName);
    }
  }

  submit(e) {
    const resetCoord = e.params && !!e.params.resetCoord;
    if (resetCoord) {
      this._clearCoordValue();
    }

    if (!this.areaTarget.classList.contains('hidden')) {
      this._clearStationInputs();
      this._clearNameInputs();
    } else if (!this.stationTarget.classList.contains('hidden')) {
      this._clearAreaInputs();
      this._clearNameInputs();
    } else if (!this.nameTarget.classList.contains('hidden')) {
      this._clearStationInputs();
      this._clearAreaInputs();
    }
  }

  searchCurrentLocation() {
    navigator.geolocation.getCurrentPosition((pos) => {
      this._clearAreaInputs();
      this._clearStationInputs();
      this._clearNameInputs();

      const { coords } = pos;
      this.latTarget.value = coords.latitude;
      this.lngTarget.value = coords.longitude;
      this.formTarget.submit();
    }, (err) => {
      console.error(err);
      const errorToast = document.querySelector('.toast.toastNg');
      errorToast.querySelector('.toast-bodyNg').innerText = '現在地の取得に失敗しました。\n時間をおいて再度お試しください。';
      errorToast.classList.remove('hide');
    });
  }

  async _getChange() {
    const apiResponse = await this._fetchCount(this.formTarget);
    if (!apiResponse) {
      return;
    }
    const { count } = apiResponse;
    this.daycareCenterCountTarget.innerText = count.toString();
  }

  async _fetchCount(form) {
    const fetchOptions = {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.csrfToken,
      },
    };

    const formData = new FormData(form);
    const queryParam = Array.from(formData.entries()).map(([key, value]) => `${key}=${value}`).join('&');

    const response = await fetch(`/api/v1/daycare_centers?${queryParam}`, fetchOptions);
    if (!response.ok) {
      console.error({ response });
      return;
    }

    return await response.json();
  }

  _resetTabSelect(tabsElement) {
    tabsElement.querySelectorAll('.tab').forEach((tab) => tab.classList.remove('selected'))
  }

  _hideAllSearchItems() {
    this.areaTarget.classList.add(this.hiddenClassName);
    if (this.locationTargets && this.locationTargets[0]) {
      this.locationTargets[0].classList.add(this.hiddenClassName);
    }

    this.stationTarget.classList.add(this.hiddenClassName);

    this.nameTarget.classList.add(this.hiddenClassName);
  }

  _clearAreaInputs() {
    const changeEvent = new Event('change');
    this.areaTarget.querySelectorAll('select').forEach((select) => {
      select.value = ''
      select.dispatchEvent(changeEvent);
    });
  }

  _clearCoordValue() {
    this.latTarget.value = '';
    this.lngTarget.value = '';
  }

  _clearStationInputs() {
    const changeEvent = new Event('change');
    this.stationTarget.querySelectorAll('select').forEach((select) => {
      select.value = ''
      select.dispatchEvent(changeEvent);
    });
  }

  _clearNameInputs() {
    const changeEvent = new Event('change');
    this.nameTarget.querySelectorAll('select').forEach((select) => {
      select.value = ''
      select.dispatchEvent(changeEvent);
    });
  }
}
