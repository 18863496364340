// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "chartkick/chart.js"
import "./controllers"
import './common/siteNavigation'
import "./dist"

{
  const slideUp = (el, duration = 300) => {
    el.style.height = el.offsetHeight + "px";
    el.offsetHeight;
    el.style.transitionProperty = "height, margin, padding";
    el.style.transitionDuration = duration + "ms";
    el.style.transitionTimingFunction = "ease";
    el.style.overflow = "hidden";
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    setTimeout(() => {
      el.style.display = "none";
      el.style.removeProperty("height");
      el.style.removeProperty("padding-top");
      el.style.removeProperty("padding-bottom");
      el.style.removeProperty("margin-top");
      el.style.removeProperty("margin-bottom");
      el.style.removeProperty("overflow");
      el.style.removeProperty("transition-duration");
      el.style.removeProperty("transition-property");
      el.style.removeProperty("transition-timing-function");
    }, duration);
  };
  const slideDown = (el, duration = 300) => {
    el.style.removeProperty("display");
    let display = window.getComputedStyle(el).display;
    if (display === "none") {
      display = "block";
    }
    el.style.display = display;
    let height = el.offsetHeight;
    el.style.overflow = "hidden";
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    el.offsetHeight;
    el.style.transitionProperty = "height, margin, padding";
    el.style.transitionDuration = duration + "ms";
    el.style.transitionTimingFunction = "ease";
    el.style.height = height + "px";
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    setTimeout(() => {
      el.style.removeProperty("height");
      el.style.removeProperty("overflow");
      el.style.removeProperty("transition-duration");
      el.style.removeProperty("transition-property");
      el.style.removeProperty("transition-timing-function");
    }, duration);
  };
  const slideToggle = (el, duration = 300) => {
    if (window.getComputedStyle(el).display === "none") {
      return slideDown(el, duration);
    } else {
      return slideUp(el, duration);
    }
  };

  
  document.addEventListener('click', function(event) {
    if (event.target.matches('.common-form__btn-conditions')) {
        const el = document.querySelector(".conditions-body");
        event.target.classList.toggle("is-active");
          slideToggle(el, 600);
    }
  });

  // 0埋め
  function addZero(num) {
    return num.toString().padStart(2, "0");
  }

  // カレンダーの結果を入力
  function setNewDate(start_date, end_date) {
    const newStartDate = start_date.getFullYear() + '-' + addZero(start_date.getMonth() + 1) + '-' + addZero(start_date.getDate());
    const newEndDate = end_date.getFullYear() + '-' + addZero(end_date.getMonth() + 1) + '-' + addZero(end_date.getDate());
    document.getElementById("q_date_start").value = newStartDate;
    document.getElementById("q_date_end").value = newEndDate;
  }

  const calendarDateUnitClick = (radioElem) => {
    //足す数値を確認
    const q_date_num = radioElem.target.value;

    //今日の日付
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    //今日の曜日
    let monday_num = 1 - today.getDay();
    let sunday_num = 7 - today.getDay();
    switch (q_date_num) {
      case 'last_thirty_days':
        startDate.setDate(startDate.getDate() - 29);
        setNewDate(startDate, endDate);
        break;
      case 'one_week':
        startDate.setDate(startDate.getDate() - 6);
        setNewDate(startDate, endDate);
        break;
      case 'two_weeks':
        startDate.setDate(startDate.getDate() - 13);
        setNewDate(startDate, endDate);
        break;
      case 'three_weeks':
        startDate.setDate(startDate.getDate() - 20);
        setNewDate(startDate, endDate);
        break;
      case 'four_weeks':
        startDate.setDate(startDate.getDate() - 27);
        setNewDate(startDate, endDate);
        break;
      case 'today':
        setNewDate(startDate, endDate);
        break;
      case 'yesterday':
        startDate.setDate(startDate.getDate() - 1);
        endDate.setDate(endDate.getDate() - 1);
        setNewDate(startDate, endDate);
        break;
      case 'this_week':
        startDate.setDate(startDate.getDate() + monday_num);
        endDate.setDate(endDate.getDate() + sunday_num);
        setNewDate(startDate, endDate);
        break;
      case 'last_week':
        startDate.setDate(startDate.getDate() + monday_num - 7);
        endDate.setDate(endDate.getDate() + sunday_num - 7);
        setNewDate(startDate, endDate);
        break;
      case 'this_month':
        startDate.setDate(1);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        setNewDate(startDate, endDate);
        break;
      case 'last_month':
        startDate.setMonth(endDate.getMonth() - 1);
        startDate.setDate(1);
        endDate.setMonth(endDate.getMonth());
        endDate.setDate(0);
        setNewDate(startDate, endDate);
        break;
      default:
        return;
    }
  };

  // カレンダー用
  document.addEventListener('turbo:load', () => {
    const dateUnits = document.querySelectorAll('input[type="radio"][name="q[date_unit]"]');
    if (!dateUnits.length) {
      return;
    }

    dateUnits.forEach((radioElem) => radioElem.addEventListener('click', calendarDateUnitClick));

    const excludeUrls = ['/vendor/dashboard', '/vendor/applicants', '/group/dashboard', '/group/applicants']
    const url = new URL(location.href);
    const dateUnitParam = url.searchParams.get('q[date_unit]');
    if (dateUnitParam || excludeUrls.includes(url.pathname)) {
      return;
    }
  });
}

// トーストを閉じる
window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.btn-close').forEach((closeButton) => {
    closeButton.addEventListener('click', (e) => {
      e.target.closest('.toast').classList.add('hide')
    });
  });
});

{
  const checkboxes = document.querySelectorAll('._status input[type="checkbox"]');
  function updateSelectBox(event) {
    const checkbox = event.target;
    const selectBox = checkbox.closest('._status').querySelector('select');
    
    if (checkbox.checked) {
      selectBox.querySelector('option[value="true"]').selected = true;
    } else {
      selectBox.querySelector('option[value="false"]').selected = true;
    }
  }
  checkboxes.forEach(function(checkbox) {
    checkbox.addEventListener('change', updateSelectBox);
  });
}
import "./channels"
