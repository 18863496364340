import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hide-daycare-centers"
export default class extends Controller {
  connect() {
    // jsで保存しておく選択リストの初期化
    sessionStorage.setItem(this.selectedHiddenCentersStorageKey(), JSON.stringify([]));
  }

  // モーダルの表示切り替え
  toggleConfirmModal() {
    document.querySelector('.modalHidden').classList.toggle("modalHiddenOpen");
  }

  // jsで保存しておく選択リストの更新
  toggleSelectedList(e) {
    e.preventDefault();
    
    let label = e.currentTarget;
    if (!label) return;

    let isChecked = label.querySelector('input').checked;
    let target_id = label.dataset.daycareCenterId;
    if (isChecked === undefined || target_id === undefined) return;

    let selectedList = JSON.parse(sessionStorage.getItem(this.selectedHiddenCentersStorageKey()) || '[]');

    let index = selectedList.indexOf(target_id);
    if (isChecked && index === -1) {
        selectedList.push(target_id);
    } else if (!isChecked && index !== -1) {
        selectedList.splice(index, 1);
    }

    sessionStorage.setItem(this.selectedHiddenCentersStorageKey(), JSON.stringify(selectedList));
  }

  // 選択リストをrailsのcontrollerに送ってdbを更新する処理
  confirmUpdate(e) {
    e.preventDefault();
    let currentPath = e.currentTarget.dataset.currentPath;
    let toCreate = currentPath != '/mypage/hidden_daycare_centers'

    if (!currentPath) return;

    let selectedIds = JSON.parse(sessionStorage.getItem(this.selectedHiddenCentersStorageKey()) || '[]');

    fetch(`/mypage/hidden_daycare_centers/bulk_${toCreate ? 'create' : 'destroy'}`, {
      method: toCreate ? 'POST' : 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({ daycare_center_ids: selectedIds }),
    })
    .then(response => {
      if (!response.ok) { throw response }
      return response.json() 
    })
    .then(data => {
      // this.toggleConfirmModal();
      window.location.href = currentPath;
    })
    .catch(error => {
      console.error(error);
    });
  }

  selectedHiddenCentersStorageKey() {
    return 'selected_hidden_daycare_center_ids';
  }
}
