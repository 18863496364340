import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mypage--line-add-friend-modal-controller"
export default class extends Controller {
  // LINE友達追加モーダルの閉じるボタンクリック時の処理です
  handleLineAddFriendModalCloseClick () {
    this.element.classList.add('c-hidden');
  }

  // リンクのクリックにて別タブで友達追加URLを開くため、モーダルは同時に閉じます
  handleLineAddFriendButtonClick () {
    this.element.classList.add('c-hidden');
  }
}
