import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="master-dashboard"
export default class extends Controller {
  connect() {}

  closeAlertUserDetailModal(e) {
    e.preventDefault();

    const { index } = e.target.dataset;
    if (!index) {
      return false;
    }

    const modal = document.querySelector(`.modalAlertUserDetail[data-index="${index}"]`);
    modal.classList.remove('modalAlertUserDetailOpen');
  }

  openAlertUserDetailModal(e) {
    e.preventDefault();
    const { index } = e.currentTarget.dataset;
    if (!index) {
      return false;
    }

    const modal = document.querySelector(`.modalAlertUserDetail[data-index="${index}"]`);
    modal.classList.add('modalAlertUserDetailOpen');
  }
}
