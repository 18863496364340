import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="common--toast"
export default class extends Controller {
  
  connect() {
    setTimeout(() => {
      this.addHideClass();
    }, 5000);
  }

  addHideClass() {
    this.element.classList.add('hide');
  }

  close() {
    this.addHideClass()
  }
}
