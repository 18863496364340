import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="daycare-center-swiper"
export default class extends Controller {
  connect() {
    this.swiper = new Swiper(".mainImageSwiper", {
      pagination: {
        el: ".swiper-pagination",
      },
      effect: "slide",
      spaceBetween: 0,
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      loop: true,
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      navigation: {
        nextEl: ".mainImageSwiperNext",
        prevEl: ".mainImageSwiperPrev",
      },
    });
  }

  disconnect() {
    // Swiperインスタンスの破棄
    this.swiper.destroy();
  }
 }
