import consumer from "./consumer"

consumer.subscriptions.create({ channel: "CsvJobProgressChannel" }, {
  connected() {},

  disconnected() {},

  received(data) {
    const logId = data.content.log_id;
    const progress = data.content.progress;

    const target = document.getElementById(`csv-job-progress-${logId}`);
    if (!target) { return; }

    if (progress === 100) {
      target.innerHTML = '完了しました。画面をリロードしてください。(100%)';
    } else {
      target.innerHTML = `処理中です。(${progress}%)`;
    }
  }
});
