import { Controller } from "@hotwired/stimulus"
import {
  validHiraKanaFormat as checkKana,
  validateBirthday as checkBirthday,
} from '../../common/validations'

// Connects to data-controller="mypage--children"
export default class extends Controller {
  /////////////////////////////////
  //  入力フォームのvalidation
  /////////////////////////////////
  static targets = ["kana", "name", "birthday", "registerButton", "kanaError", "birthdayError"];
  connect() {
    $('.childrenFormInputBirthday')
      .on('change', () => this.validateForm())
      .datepicker({
        language:'ja',
        format: 'yyyy-mm-dd',
        autoclose: true,
      });
    $('.childrenFormInputRadioArea input:radio[name="mypage_children_form[sex]"]').on('change', () => this.validateForm());
  }

  validateForm() {
    this.checkBirthdayFormat();
    this.checkKanaFormat();
    this.checkRequiredFields();
  }

  checkKanaFormat() {
    const kana = this.kanaTarget.value;
    const isKana = checkKana(kana);

    if(isKana == undefined || isKana) {
      this.kanaErrorTarget.textContent = '';
    } else {
      this.kanaErrorTarget.textContent = 'ふりがなはひらがなのみ入力してください';
    }
  }

  checkBirthdayFormat() {
    const birthdayString = this.birthdayTarget.value;
    const validResult = checkBirthday(birthdayString);

    if(validResult == undefined || validResult == "Valid date") {
      this.birthdayErrorTarget.textContent = '';
    } else if (validResult == "Future date") {
      this.birthdayErrorTarget.textContent = '本日以前の日付を選択してください';
    } else {
      this.birthdayErrorTarget.textContent = '正しい日付を入力してください';
    }
  }

  checkRequiredFields() {
    let allFieldsFilled = true;
    const requiredFields = document.querySelectorAll('[required],[required="required"]');

    requiredFields.forEach(field => {
      if (!field.value.trim()) {
        allFieldsFilled = false;
      }
    });

    if (allFieldsFilled && !this.kanaErrorTarget.textContent && !this.birthdayErrorTarget.textContent) {
      this.registerButtonTarget.disabled = false;
    } else {
      this.registerButtonTarget.disabled = true;
    }
  }

  /////////////////////////////////
  //  削除モーダル表示操作
  /////////////////////////////////
  closeDeleteConfirmationModal(e) {
    if (e) {
      const { callPreventDefault } = e.target.dataset;
      if (!callPreventDefault || (callPreventDefault && callPreventDefault.toLowerCase() === 'true')) {
        // callPreventDefault が falsy または、'true'の場合 e.preventDefault() を呼び出す
        e.preventDefault();
      }
    }
    const modal = e.target.closest('.modalChildDelete');
    modal.classList.remove('modalChildDeleteOpen');
  }

  openDeleteConfirmationModal(e) {
    e.preventDefault();
    let { target } = e;
    if (target.tagName.toLowerCase() !== 'a') {
      target = target.closest('.childrenInfoButton');
    }

    const { index } = target.dataset;
    if (index === undefined) {
      return false;
    }

    const modal = document.querySelector(`.modalChildDelete[data-index="${index}"]`);
    modal.classList.add('modalChildDeleteOpen');
  }


  /////////////////////////////////
  //  入力フォームのスライドインアウト
  /////////////////////////////////
  slideInChildForm(e) {
    e.preventDefault();

    let type = e.currentTarget.dataset.type;
    let childId = e.currentTarget.dataset.childId;
    let childNum = e.currentTarget.dataset.childNum;
    
    if (type === 'edit' && (!childId || !childNum)) {
      return false;
    }

    // サーバにリクエストを送る
    let url = type === 'new' ? '/mypage/children/new_modal' : `/mypage/children/${childId}/edit_modal?child_num=${childNum}`;
    $.ajax({
      url: url,
      type: "GET",
      dataType: "html"
    }).done((html) => {
      // モーダルの内容を更新
      document.querySelector('.childrenForm').innerHTML = html;

      // モーダルを表示
      document.querySelector(".childrenForm").classList.toggle("childrenFormActive");

    }).fail((error) => {
      console.error(`Error: ${error}`);
    });
  }

  slideOutChildForm(e) {
    e.preventDefault();

    document.querySelector('.childrenForm').innerHTML = "";
    document.querySelector(".childrenForm").classList.toggle("childrenFormActive");
  }
}
