import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="common--modal"
export default class extends Controller {
  
  connect() {
    this.open()
    this.scrollToElement()
  }

  open() {
    this.element.classList.add('open');
  }

  close(e) {
    e.preventDefault()
    this.element.classList.remove('open');
  }

  scrollToElement() {
    this.element.scrollIntoView({ behavior: 'auto', block: 'start' });
  }
}
