import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="master--analytics"
export default class extends Controller {
  static targets = ['submitBtn']

  connect() {
    this.initialData = JSON.parse(this.element.dataset.chartInitialData);

    // グラフ描画が完了しないと要素が取得できないため、描画完了まで待つ
    setTimeout(() => {
      this._loadChart();
    }, 500);
  }

  search(e) {
    e.preventDefault();
    this._submit();
  }

  _submit() {
    this.submitBtnTarget.form.submit();
  }

  /*
   * 集計単位変更関数群
   */
  search_by_daily(e) {
    e.preventDefault();
    this._submit_with_search_unit('day');
  }

  search_by_weekly(e) {
    e.preventDefault();
    this._submit_with_search_unit('week');
  }

  search_by_monthly(e) {
    e.preventDefault();
    this._submit_with_search_unit('month');
  }

  _submit_with_search_unit(search_unit){
    this.submitBtnTarget.form.querySelector('input[name="q[search_unit]"]').value = search_unit;
    this._submit();
  }

  /*
   * chart更新用関数群
   */
  update_bar_chart(e) {
    e.preventDefault();
    this._update_chart(e, 'bar');
  }

  update_line_chart(e) {
    e.preventDefault();
    this._update_chart(e, 'line');
  }

  _update_chart(e, chart_type) {
    const radioButton = e.target;
    const isChecked = radioButton.checked;
    const keyValue = radioButton.value;

    if (isChecked) {
      this.submitBtnTarget.form.querySelector('input[name="q[' + chart_type + '_chart_item]"]').value = keyValue;
      this._loadChart();
    }
  }

  // グラフ描画
  _loadChart() {
    const chart = Chartkick.charts["analytics-chart"];
    const barChartItem = this.submitBtnTarget.form.querySelector('input[name="q[bar_chart_item]"]').value;
    const lineChartItem = this.submitBtnTarget.form.querySelector('input[name="q[line_chart_item]"]').value;
    const newData = [
      {
        name: this._getTitle(lineChartItem),
        data: this._extractData(lineChartItem),
        dataset: { yAxisID: 'y' },
        curve: false
      },
      {
        name: this._getTitle(barChartItem),
        data: this._extractData(barChartItem),
        dataset: { type: 'bar', yAxisID: 'y1' }
      }
    ];

    chart.updateData(newData, this._getOptions())
  }

  // グラフ用データの抽出
  _extractData(attribute) {
    return Object.entries(this.initialData).map(([date, stats]) => {
      return [date, stats[attribute]];
    });
  }

  // グラフ用オプション
  _getOptions() {
    return {
      colors: ["#E87400", "#1FA39E"],
      library: {
        scales: {
          y: this._getDefaultOptions('left', this._getTitle(this.submitBtnTarget.form.querySelector('input[name="q[line_chart_item]"]').value)),
          y1: this._getDefaultOptions('right', this._getTitle(this.submitBtnTarget.form.querySelector('input[name="q[bar_chart_item]"]').value))
        }
      }
    }
  }

  _getDefaultOptions(potision, text) {
    return {
      type: 'linear',
      display: true,
      position: potision,
      grid: {
        drawOnChartArea: false
      },
      title: {
        display: true,
        text: text,
        color: '#111',
        font: {
          family: 'sans-serif',
          size: 16,
          weight: 'bold',
          lineHeight: 1.2,
        }
      }
    }
  }

  // グラフ用ラベル
  _getTitle(value) {
    switch(value) {
      case 'session':
        return 'セッション数';
      case 'top':
        return 'PV数-TOP';
      case 'search_results':
        return 'PV数-一覧表示';
      case 'daycare_center_detail':
        return 'PV数-園詳細';
      case 'form':
        return 'PV数-フォーム';
      case 'member_registration':
        return '会員登録';
      case 'favorite':
        return 'お気に入り登録';
      case 'total':
        return 'CV合計';
      case 'temporary_no_enrollment':
        return '一時保育';
      case 'temporary_want_enrollment':
        return '入園前提';
      case 'tour':
        return '園見学';
      case 'tel_click':
        return 'TEL';
      default:
        return '';
    }
  }
}
