import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="common--csv-upload-area"
export default class extends Controller {
  static targets = ["csvUploadArea", "csvFileField", "csvFileName", "csvSubmit"];

  connect() {}

  dragover(e) {
    e.preventDefault();

    const uploadArea = this.csvUploadAreaTarget;
    if (!uploadArea.classList.contains('dragging')) {
      uploadArea.classList.add('dragging');
    }
  }

  dragleave(e) {
    e.preventDefault();

    const uploadArea = this.csvUploadAreaTarget;
    if (uploadArea.classList.contains('dragging')) {
      uploadArea.classList.remove('dragging');
    }
  }

  dropFile(e) {
    e.preventDefault();
  
    const files = e.dataTransfer.files;
    if (files.length && files[0].type === 'text/csv') {
      this.csvFileFieldTarget.files = files;
      this.selectFile();
    }

    this.dragleave(e);
  }

  selectFile() {
    this.csvSubmitTarget.disabled = this.csvFileFieldTarget.files.length == 0;
    this.csvFileNameTarget.textContent = this.csvSubmitTarget.disabled ? '選択されていません' : this.csvFileFieldTarget.files[0].name;
  }
}
