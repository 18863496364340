import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mypage--account"
export default class extends Controller {
  static targets = [];
  connect() {
    // LINE連携ログインの場合はパスワード登録が存在しないため、cognitoのネイティブユーザー以外でログインしている場合は、パスワード変更ボタンを非表示にします
    window.auth.getCurrentUser()
    .then((res) => {
      let toHide = false;

      if (res.success) {
        // LINE連携の場合と、マルチログインの場合に、フロントの現在実際にログインしているユーザー名を取得します
        const actual_user_name = res.data.username;

        // LINE連携ユーザーの場合は、先頭が'line_'で始まるため、そちらにて判定します
        if (actual_user_name.startsWith('line_')) {
          console.log('Current actual user is LINE federated user, hiding change password button.');
          toHide = true;
        }
      } else {
        console.log('Could not get current user, hiding change password button.')
        toHide = true;
      }

      if (toHide) {
        document.querySelectorAll('[data-hide-target-when-not-cognito-login="true"]').forEach((el) => {
          el.classList.add('c-hidden');
        });
      }
    });
    
  }

  slideInOutForm(e) {
    e.preventDefault();

    let type = e.currentTarget.dataset.type;
    
    if (!type) {
      return false;
    }

    document.querySelector(`#accountsFormModal${type}`).classList.toggle("accountsFormModalActive");
  }

  async handleMultiLoginLinkButtonClick (e) {
    const { linkType, linkMultiLoginStartPath } = e.target.dataset;

    // まずサーバーにリンク開始を通知し、リンク先のprimary_user_code_to_linkをセッションに格納します
    const { success } = await fetch(linkMultiLoginStartPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ link_type: linkType })
    }).then(res => res.json());

    if (success) {
      // マルチログインの場合、cognitoユーザーをprimaryユーザーに紐づけてcognitoユーザープールに登録します
      switch (linkType) {
        case 'line': {
          // 紐付けフラグがセッションに格納された状態で、LINEログイン連携を開始します
          window.auth.federatedSignIn({ customProvider: 'LINE'});
          break;
        }
      }
    } else {
      // ログインしている場合はこちらに入ることはない想定です
      window.alert('ログイン連携処理を開始できませんでした。時間を置いてから再度お試しください。');
    }
  }
}
