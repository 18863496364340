import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vendor-mail-templates"
export default class extends Controller {

  static targets = ['openDialog'];

  connect() {
    this.confirmDialog = document.querySelector('#confirmDialog');
    this.confirmDialogDeleteButton = document.querySelector('#confirmDeleteButton');

    this.openDialogTarget.addEventListener('click', (e) => {
      const templateId = this.openDialogTarget.dataset.templateId
      e.preventDefault();

      this.confirmDialog.classList.add('show');
      this.confirmDialog.classList.remove('hide');
      this.confirmDialog.style.display = 'block';
      this.confirmDialogDeleteButton.parentNode.setAttribute("action", `/vendor/mail_templates/${templateId}`);
    });
  }
}
