import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    $('.select2-select-daycare-center').each(function() {
      var isMultiple = $(this).attr('multiple') !== undefined;
    
      $(this).select2({
        dropdownAutoWidth: true,
        multiple: isMultiple,
        closeOnSelect: !isMultiple, // `multiple`がtrueの場合、選択時にドロップダウンを閉じない
        ajax: {
          url: '/api/v1/suggest_daycare_centers',
          dataType: 'json',
          delay: 50,
          data: function(params) {
            return { term: params.term };
          },
          processResults: function (data, params) {
            return { results: $.map(data, function(obj) {
              return { id: obj.id, text: obj.text };
            })};
          }
        },
        placeholder: "検索してください",
      });
    });
  }
}