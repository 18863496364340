document.addEventListener('turbo:load', () => {
  const globalNavHumburger = document.querySelector('#globalNavHumburger');
  const globalSpMenu = document.querySelector('#globalNavSpMenu');
  const bodyFixed = $('#terms, #application_front_conform, #application, #application_front_conform, #contact_conform, #contact, #contact_thanks, #cookiepolicy, #error, #privacy, #temporary_custody, #thanks, #trial_application_conform, #trial_application, #trial_application_thanks, #about, #detail, #faq, #guide, #list, #login, #accounts, #children, #entries, #register, #searchpoint, #seminar, #index, #type, #news, #news_detail');
  const globalNavSpClose = document.querySelector('#globalNavSpClose');

  const btnToggleHumburger = (el) => {
    el.classList.toggle('globalNavSpMenuActive');
    bodyFixed[0].classList.toggle('bodyFixed');
  }

  if (globalNavHumburger) {
    globalNavHumburger.addEventListener('click', () => {
    btnToggleHumburger(globalSpMenu);
    }, false);
  }

  if (globalNavSpClose) {
    globalNavSpClose.addEventListener('click', () => {
    btnToggleHumburger(globalSpMenu);
    }, false);
  }

  //フッタートップへ戻るボタン
  const footerTopButton = document.querySelector("#footerTopButton");
  if (footerTopButton) {
    footerTopButton.addEventListener("click", () => {
      window.scroll({ top: 0, behavior: "smooth" });
    });
  }
})
