import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vendor--chat-attachment"
export default class extends Controller {

  static targets = ['files', 'previewAreas', 'imageTemplate', 'fileTemplate'];

  connect() {
    window.addEventListener('beforeunload', async () => await this.deleteFiles());
  }

  /**
   * ファイル選択時イベント
   */
  selectFiles() {
    Array.from(this.filesTarget.files).forEach((file) => {
      this._upload(file);
    });
    // ファイル選択クリア
    this.filesTarget.value = '';
  }

  async deleteFile(e) {
    const closestArea = e.target.closest('.preview-area');
    const { id } = closestArea.dataset;
    if (!id) return;

    try {
      const response = await this._deleteImage(id);
      console.log({ response })
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.error);
      }
      closestArea.remove();
    } catch (e) {
      console.error(e);
    }
  }

  deleteFiles() {
    document.querySelectorAll('.preview-area:not(._template)').forEach((element) => {
      const { id } = element.dataset;
      if (!id) {
        return;
      }
      this._deleteImage(id);
    });
  }

  /**
   * Textarea の高さを動的に変更する. 1行が折り返す場合、未対応.
   * @param e
   */
  changeTextarea(e) {
    const { lineHeight, paddingTop, paddingBottom, borderTop, borderBottom } = window.getComputedStyle(e.target);
    const asNumbers = {
      lineHeight: parseFloat(lineHeight),
      paddingTop: parseFloat(paddingTop),
      paddingBottom: parseFloat(paddingBottom),
      borderTop: parseFloat(borderTop),
      borderBottom: parseFloat(borderBottom),
    };
    const textLineCount = e.target.value.split(/\r?\n/).length;
    const notTextAreaHeight = asNumbers.paddingTop + asNumbers.paddingBottom + asNumbers.borderTop + asNumbers.borderBottom;
    const maxHeight = asNumbers.lineHeight * this.MAX_LINE_COUNT + notTextAreaHeight;
    const { clientHeight } = e.target;

    if (clientHeight >= maxHeight) {
      // Textarea の高さが最大値を超えていた場合、それ以上拡張しない
      return;
    } else if (textLineCount < 1 || textLineCount > this.MAX_LINE_COUNT) {
      // 0行、または9行以上の場合変更しない
      return;
    }
    e.target.style.height = `${textLineCount * asNumbers.lineHeight + notTextAreaHeight}px`;
  }

  /**
   * ファイルアップロードAPIを実行
   * @param file
   */
  _upload(file) {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content;
    const formData = new FormData();
    formData.append('file', file);
    const fetchOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
      body: formData,
    };
    fetch('/vendor/applicants/chats/attachments', fetchOptions)
      .then(res => res.json())
      .then(data => this._showFiles(file, data))
      .catch(e => console.error(e));
  }

  /**
   * アップロードしたファイルをサムネイル表示
   * @param file
   * @param data
   */
  _showFiles(file, data) {
    const { name, type } = file;
    const { template, isImage } = this._getTemplateByFileType(type);
    const cloned = template.cloneNode(true);
    cloned.classList.remove('_template');
    cloned.dataset.filename = name;
    cloned.dataset.id = data.id;
    cloned.querySelector('.delete-btn').addEventListener('click', (e) => this.deleteFile(e));
    cloned.querySelector('input[type=hidden]').value = data.id;
    if (isImage) {
      this._cloneImage(cloned, file);
    } else {
      this._cloneFile(cloned, name, type);
    }
  }

  /**
   * DOM取得
   * @param fileType
   * @returns {{template: *, isImage: boolean}}
   */
  _getTemplateByFileType(fileType) {
    if (fileType.indexOf('image') >= 0) {
      // 画像の場合、プレビューを表示する
      return { template: this.imageTemplateTarget, isImage: true };
    } else {
      // それ以外
      return { template: this.fileTemplateTarget, isImage: false };
    }
  }

  /**
   * 画像表示のDOMをコピー
   * @param template
   * @param file
   */
  _cloneImage(template, file) {
    template.querySelector('.preview-image').src = URL.createObjectURL(file);
    this.previewAreasTarget.insertAdjacentElement('beforeend', template);
  }

  /**
   * ファイル表示のDOMをコピー
   * @param template
   * @param name
   * @param type
   */
  _cloneFile(template, name, type) {
    let extension;
    const nameMatch = name.match(/\.(\w+)$/);
    const typeMatch = type.match(/\/(\w+)$/);
    if (nameMatch && nameMatch[1]) {
      extension = nameMatch[1];
    } else if (typeMatch && typeMatch[1]) {
      extension = typeMatch[1];
    } else {
      extension = type;
    }
    extension = extension.toUpperCase();

    template.querySelector('.heading-extension').innerText = extension;
    template.querySelector('.file-info .filename').innerText = name;
    template.querySelector('.file-info .filename').title = name;
    template.querySelector('.file-info .extension').innerText = extension;
    this.previewAreasTarget.insertAdjacentElement('beforeend', template);
  }

  _deleteImage(id) {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content;
    const fetchOptions = {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
      keepalive: true,
    };
    const entryId = this._getEntryId();
    const params = new URLSearchParams([['eid', entryId]]);
    return fetch(`/vendor/applicants/chats/attachments/${id}?${params}`, fetchOptions);
  }

  _getEntryId() {
    const url = new URL(location.href);
    return url.pathname.split('/').findLast(() => 1);
  }
}
