import { Controller } from "@hotwired/stimulus"
import { v4 as uuid } from 'uuid'

export default class extends Controller {

  connect() {
    this.state = {
      images: [],
      errors: [],
      groupId: "",
      daycareCenterId: ""
    }
    this.daycareCenters = JSON.parse($('#daycare_centers').text())
    this.render()

    $('.select2-select-add').select2({
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      placeholder: '検索してください',
    })


    $('.select2-select-add-daycare-center').select2({
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: false,
      ajax: {
        url:'/api/v1/suggest_daycare_centers',
        dataType: 'json',
        delay: 50,
        data: function(params) {
          return { term: params.term, not_group: true };
        },
        processResults: function (data, params) {
          return { results: $.map(data, function(obj) {
              return { id: obj.id, text: obj.text };
            })};
        }
      },
      placeholder: "検索してください",
    })

    $("#group_id").on("select2:select", this.changeGroup.bind(this))
    $("#daycare_center_id").on("select2:select", this.changeDaycareCenter.bind(this))
  }

  upload(e) {
    e.preventDefault()
    if (!e.target.files || !e.target.files.length) {
      return
    }
    const {images} = this.state
    this.setState({
      images: [...images, ...Array.from(e.target.files).map(file => ({
        file,
        uuid: uuid()
      }))],
      errors: []
    })
    e.target.value = ''
  }

  changeGroup(e) {
    const groupId = e.target.value
    this.setState({
      groupId: e.target.value,
      daycareCenterId: "",
      errors: []
    })
    $('#daycare_center_id').val("").trigger('change')
  }

  changeDaycareCenter(e) {
    this.setState({
      daycareCenterId: e.target.value,
      groupId: "",
      errors: []
    })
    $('#group_id').val("").trigger('change')
  }

  dragOver(e) {
    e.preventDefault()
    $('.drop-area').addClass('drag-over')
  }

  dragLeave(e) {
    e.preventDefault()
    $('.drop-area').removeClass('drag-over')
  }

  drop(e) {
    e.preventDefault()
    if (!e.dataTransfer.files || !e.dataTransfer.files.length) {
      return
    }
    const {images} = this.state
    this.setState({
      images: [...images, ...Array.from(e.dataTransfer.files).map(file => ({
        file,
        uuid: uuid()
      }))],
      errors: []
    })
    $('.drop-area').removeClass('drag-over')
  }

  delete(e) {
    e.preventDefault()
    const {dataset: {uuid}} = e.target
    const {images} = this.state
    this.setState({
      images: images.filter(image => image.uuid !== uuid),
      errors: []
    })
  }

  async setState(newState) {
    this.state = {...this.state, ...newState}
    await this.render()
  }

  getFileDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  close() {
    const event = new CustomEvent('closemodal')
    window.dispatchEvent(event)
  }

  submit(e) {
    e.preventDefault()
    if (this.invalid()) {
      return
    }
    const {images, groupId, daycareCenterId} = this.state
    const formData = new FormData();
    formData.append('group_id', groupId)
    formData.append('daycare_center_id', daycareCenterId)
    images.forEach(image => {
      formData.append('files[]', image.file)
    })
    fetch('/master/daycare_center_images', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.getCsrfToken(),
        'Accept': 'text/vnd.turbo-stream.html'
      },
      body: formData
    }).then(res => {
      if (res.ok) {
        this.close()
      }
      return res.text()
    }).then(html => Turbo.renderStreamMessage(html))
  }

  invalid() {
    const {images, groupId, daycareCenterId} = this.state
    const errors = []
    if (images.length > 30) {
      errors.push('一括で取り込むことができるファイルは30件までです。')
    }
    if (!groupId && !daycareCenterId) {
      errors.push('グループまたは園のいずれかを指定ください。')
    }
    if (!images.length) {
      errors.push('画像をアップロードしてください。')
    }
    this.setState({errors})
    
    return !!errors.length
  }

  getCsrfToken() {
    const metas = document.getElementsByTagName('meta');
    for (let meta of metas) {
        if (meta.getAttribute('name') === 'csrf-token') {
            return meta.getAttribute('content');
        }
    }
    return '';
  }

  async render() {
    const {images, groupId, daycareCenterId, errors} = this.state
    const errorsContents = $('#errors').empty()
    if (errors.length) {
      errorsContents.append(
        errors.map(error => `<p>${error}</p>`)
      )
    }
    $('.size').text(images.length)
    const contents = $('#images').empty()
    for (const {file, uuid} of images) {
      const $contents = $('#template').clone().css('display', 'block').removeAttr('id');
      $contents.find('.delete').attr('data-uuid', uuid)
      $contents.find('.filename').text(file.name)
      $contents.find('.img').attr('src', await this.getFileDataURL(file))
      contents.append($contents);
    }
  }
}