import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="common--date-change"
export default class extends Controller {
  static targets = ['radios'];

  connect() {
  }

  dateChange(e) {
    this.radiosTargets.forEach((element) => element.checked = false);
  }
}
