import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="master-invoice"
export default class extends Controller {

  connect() {}

  changeRowNum(event) {
    event.preventDefault();

    var selectedValue = event.target.value;

    // 既存のクエリにper_pageの値を追加してリダイレクトする
    var url = new URL(window.location.href);
    url.searchParams.set('per_page', selectedValue); 
    url.searchParams.set('page', 1); // iページ目にする

    window.location.href = url.href;
  }

  resetFormInput(event) {
    event.preventDefault();
    const formId = event.target.dataset.formId;
    const form = document.getElementById(formId);
      if (form) {
        form.reset();
      }
  }
}
