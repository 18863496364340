import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="common--daycare-center-images"
export default class extends Controller {

  connect() {
    console.log('daycare_center_images_controller')
  }
  
  upload(e) {
    const file = e.target.files[0]
    $('.filename').text(file.name)
  }
}
