import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    $('.select2-select').select2({
      dropdownAutoWidth: true,
      width: '340px',
      closeOnSelect: false,
      placeholder: '検索してください',
    })

    $('.select2-select-daycare-center').select2({
      dropdownAutoWidth: true,
      width: '340px',
      closeOnSelect: false,
      ajax: {
        url:'/api/v1/suggest_daycare_centers',
        dataType: 'json',
        delay: 50,
        data: function(params) {
          return { term: params.term };
        },
        processResults: function (data, params) {
          return { results: $.map(data, function(obj) {
              return { id: obj.id, text: obj.text };
            })};
        }
      },
      placeholder: "検索してください",
    })
  }

  close() {
    const event = new CustomEvent('closemodal')
    window.dispatchEvent(event)
  }
}