import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants-details"
export default class extends Controller {
  closeEntryStatusFormModal(e) {
    e.preventDefault();

    const modal = document.querySelector('.modalEntryStatusForm');
    modal.classList.remove('modalEntryStatusFormOpen');
  }

  openEntryStatusFormModal(e) {
    e.preventDefault();

    const modal = document.querySelector('.modalEntryStatusForm');
    modal.classList.add('modalEntryStatusFormOpen');
  }
}
