
// cognitoから返却されるエラーオブジェクトを、エラーメッセージに変換します
export const formatCongnitoErrorMessage = (error) => {
  let errorMessage = '認証エラーが発生いたしました。';
  
  try {
    switch (error.message) {
      case 'Incorrect username or password.':
        errorMessage = 'メールアドレスかパスワードが異なります。';
        break;
      case 'User is not confirmed.':
      case 'PreSignUp failed with error Email is not verified. Please verify your email address..':
        errorMessage = 'メールアドレスの検証が完了しておりません。';
        break;
      case 'Username should be an email.':
      case 'Invalid email address format.':
        errorMessage = '無効なメールアドレスでございます。';
        break;
      case 'An account with the given email already exists.':
        errorMessage = 'ご入力いただきましたメールアドレスは既に登録されております。';
        break;
      case 'Invalid code provided, please request a code again.':
        errorMessage = '検証コードが無効となっております。お手数をおかけいたしますが、再度始めからお手続きをお願いいたします。';
        break;
      case 'User cannot be confirmed. Current status is CONFIRMED':
        errorMessage = '既にメールアドレス検証が完了しております。';
        break;
      case 'Attempt limit exceeded, please try after some time.':
        errorMessage = '回数制限を超えました。しばらく時間をおいてから再度お試しください。';
        break;
      default:
        errorMessage = '認証エラーが発生いたしました。時間をおいて再度お試しください。';
        break;
    }
  } catch (err) {
    errorMessage = '予期せぬ認証エラーが発生いたしました。';
  }

  return errorMessage;
}

// cognitoへのサインアップ前に、同一メールアドレスが既に登録されていないか確認します
export const fetchToCheckEmailUniqueness = async (email) => {
  let errorMessage = null;
  let isUnique = null;
  let isVerified = null;
  let duplicatedUserCode = null;

  const data = await fetch(`/signup/check_email_uniqueness_before_cognito_signup?email=${ encodeURIComponent(email) }`)
    .then(res => res.json())
    .catch(e => console.error(e));

  if (!data) {
    errorMessage = 'メールアドレスの問い合わせに失敗しました。時間をおいて再度お試しください。';
  }

  if (data.is_unique) {
    isUnique = true;
  } else {
    isUnique = false;

    if (data.is_verified) {
      isVerified = true;

      errorMessage = 'ご入力いただきましたメールアドレスは既に登録されています';
    } else {
      isVerified = false;
      
      // もし未検証状態の場合、検証コードメールを紛失している場合などが考えられるため、検証コードを再送するためユーザーコードを返却します
      duplicatedUserCode = data.duplicated_user_code;
    }
  }

  return { isUnique, errorMessage, isVerified, duplicatedUserCode };
}
