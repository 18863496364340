import { Controller } from "@hotwired/stimulus"
import { v4 as uuid } from 'uuid'

// Connects to data-controller="master--area-informations"
export default class extends Controller {

  connect() {
    this.state = {
      informations: [],
    }
    this.load()

    $('.nearby-city-select').select2({
      dropdownAutoWidth: true,
      width: '100%',
      multiple: true,
      closeOnSelect: false,
      placeholder: '検索してください',
    })
  }

  setState(newState) {
    this.state = {...this.state, ...newState}
  }

  load() {
    const informations = JSON.parse($('#current-informations').text()).map(info => ({ ...info, uuid: uuid(), errors: [] }))
    this.setState({informations});
    this.render();
  }

  add() {
    const {informations} = this.state
    this.setState({informations: [...informations, {
      code: '',
      title: '',
      content: '',
      uuid: uuid(),
      errors: []
    }]})
    this.render()
  }

  changeTitle({target: {value, dataset: {uuid}}}) {
    this.changeValue(uuid, 'title', value)
  }

  changeCode({target: {value, dataset: {uuid}}}) {
    this.changeValue(uuid, 'code', value)
  }

  changeContent({target: {value, dataset: {uuid}}}) {
    this.changeValue(uuid, 'content', value)
  }

  changePriorityUp({target: {dataset: {uuid}}}) {
    const {informations} = this.state
    const index = informations.findIndex(info => info.uuid === uuid)
    let newState = [...informations]
    if (index > 0 && index < informations.length) {
      const movedObject = informations[index];
      newState.splice(index, 1)
      newState.splice(index - 1, 0, movedObject)
    }
    this.setState({informations: newState})
    this.render()
  }

  changePriorityDown({target: {dataset: {uuid}}}) {
    const {informations} = this.state
    const index = informations.findIndex(info => info.uuid === uuid)
    let newState = [...informations]
    if (index >= 0 && index < informations.length - 1) {
      const movedObject = informations[index]
      newState.splice(index, 1)
      newState.splice(index + 1, 0, movedObject)
    }
    this.setState({informations: newState})
    this.render()
  }

  delete({target: {dataset: {uuid}}}) {
    const {informations} = this.state
    this.setState({informations: informations.filter(info => info.uuid !== uuid)})
    this.render()
  }

  openDialog({target: {dataset: {uuid}}}) {
    $('.confirmDialog').each(function() {
      const $el = $(this);
      if ($el.data('uuid') === uuid) {
        $el.addClass('show');
      }
    });
  }

  closeDialog() {
    $('.confirmDialog').removeClass('show');
  }

  changeValue(uuid, key, value) {
    const {informations} = this.state
    this.setState({informations: informations.map(info => {
      if (info.uuid === uuid) {
        return {
          ...info,
          [key]: value,
          errors: []
        }
      }
      return info
    })})
  }

  submit() {
    this.check()
    const {informations} = this.state
    if (informations.some(info => !!info.errors.length)) {
      this.render()
      return
    }
    $("#form").submit()
  }

  check() {
    const {informations} = this.state
    const newState = informations.map(info => {
      const errors = []
      if (!info.code) {
        errors.push(["code", "管理コードを入力してください"])
      }
      if (!info.title) {
        errors.push(["title", "見出しを入力してください"])
      }
      if (!info.content) {
        errors.push(["content", "本文を入力してください"])
      }
      return {
        ...info,
        errors
      }
    })
    this.setState({informations: newState})
  }

  render() {
    const {informations} = this.state
    const contents = $('#contents-area').empty()
    informations.forEach((info, i) => {
      const $contents = $('#template').clone().css('display', 'flex').removeAttr('id');
      $contents.find('.input-code').val(info.code).attr('name', `informations[${i}][code]`)
      $contents.find('.input-title').val(info.title).attr('name', `informations[${i}][title]`)
      $contents.find('.input-content').val(info.content).attr('name', `informations[${i}][content]`)
      $contents.find('.title__number').text(i + 1);
      $contents.find('.input-uuid').attr('data-uuid', info.uuid)
      if (i === 0) {
        $contents.find('.priority-up').prop('disabled', true)
      }
      if (i === informations.length - 1 ) {
        $contents.find('.priority-down').prop('disabled', true)
      }
      info.errors.forEach(([key, errorMessage]) => $contents.find(`.input-area-input__error.${key}`).text(errorMessage))
      contents.append($contents);
    });
  }
}
