/**
 * 数字を漢数字に変換
 * @param {*} number number
 * @returns string like ①, ②, ③, ④, ⑤, ⑥, ⑦, ⑧, ⑨, ⑩
 */
export function getKanjiNumber(number) {
  const kanjiNumbers = ["①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", "⑩"];
  return kanjiNumbers[number - 1] || number.toString();
}

/**
 * URLのクエリパラメータを用いて特定のpathにformを送信する処理
 * @param {*} path string(ex)'/master/vendors/export_logs'
 */
export function postFormWithQueryParams(path) {
   // フォームを動的に作成
  var form = document.createElement('form');
  form.method = 'post';
  form.action = path;

  // 現在のURLのクエリパラメータをフォームに追加
  var queryParams = new URLSearchParams(location.search);
  queryParams.forEach(function(value, key) {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  });

  // CSRFトークンを追加（Railsの場合）
  var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  var inputCsrf = document.createElement('input');
  inputCsrf.type = 'hidden';
  inputCsrf.name = 'authenticity_token';
  inputCsrf.value = csrfToken;
  form.appendChild(inputCsrf);

  document.body.appendChild(form);
  form.submit();
}
