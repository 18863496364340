import { Controller } from "@hotwired/stimulus"
import { postFormWithQueryParams } from "../../common/globalFunctions";

// Connects to data-controller="master-vendors"
export default class extends Controller {
  static targets = ['groups', 'daycareCenters'];

  connect() {
  }

  toggleModal(e) {
    e.preventDefault();
    document.querySelector(".vendors-importExport-modal").classList.toggle("none");
  }

  toggleGroupModal(e) {
    e.preventDefault();
    document.querySelector(".vendors-importExport-modal.groups").classList.toggle("none");
  }

  csvDownload(e) {
    e.preventDefault();
    postFormWithQueryParams('/master/vendors/export_logs');
  }

  changeGroups() {
    const groupIds = Array.from(this.groupsTarget.selectedOptions).map((option) => {
      return option.value;
    });
    fetch(`/api/v1/vendors?group_ids=${groupIds.join(",")}`)
      .then(res => res.json())
      .then(options => {
        this.daycareCentersTarget.innerHTML = '';
        options.data.forEach(option => {
          var opt = document.createElement('option');
          opt.textContent = option.name;
          opt.value = option.id;
          this.daycareCentersTarget.appendChild(opt);
        });
      });
  }
}