import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sort-daycare-centers"
export default class extends Controller {
  connect() {}
  
  openOptionSelect(e) {
    e.preventDefault();

    // html要素に一度だけ反応するクリックイベント(閉じる)を設定する
    this.onceClick = this.onceClick.bind(this)
    document.documentElement.addEventListener('click', this.onceClick)

    e.currentTarget.parentElement.classList.toggle("opened");
    e.stopPropagation();
  }

  onceClick(e) {
    e.preventDefault();

    const customSelect = this.element.querySelector('.custom-select')
    if (customSelect) {
      customSelect.classList.remove('opened')
    }
    document.documentElement.removeEventListener('click', this.onceClick)
  }

  clickOption(e) {
    // selectboxの表示を切り替える処理
    let wrapper = document.querySelector(".custom-select-wrapper");
    wrapper.querySelector(".selection").classList.remove("selection");

    let selected = e.currentTarget;
    selected.classList.add("selection");

    wrapper.querySelector('.custom-select').classList.remove("opened");
    wrapper.querySelector(".custom-select-trigger").textContent = selected.textContent;

    // apiを叩いて表示を更新する処理
    let url = `/mypage/entries?sort=${selected.dataset.value}`;
    if (window.location.search) {
      url += `&${window.location.search.split('?')[1]}`;
    }
    const fetchOptions = {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    };
    fetch(url, fetchOptions)
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error, status = ${res.status}`);
        }
        return res.text();
      })
      .then(data => { 
        document.querySelector('.card_area').innerHTML = data;
      })
      .catch(error => {
        console.log(error.message);
      });
    }
}
