import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="common--invoice-modal"
export default class extends Controller {
  connect() {}

  toggleModal(e) {
    e.preventDefault()
    this._getModal().classList.toggle('none')
  }

  close() {
    this._getModal().classList.add('none')
  }

  _getModal() {
    return document.querySelector('.c-invoice__modal')
  }
}
